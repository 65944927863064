import { ChangeHistory } from '@mui/icons-material'
import AirportIconSvg from '../components/Common/SvgIcons/AirportIconSvg/AirportIconSvg'
import HomeIconSvg from '../components/Common/SvgIcons/HeartIconSvg/HomeIconSvg'
import HeartIconSvg from '../components/Common/SvgIcons/HomeIconSvg/HeartIconSvg'
import { insuranceCategoryType } from './insuranceCategory'

export const insuranceFieldType = {
  elementary: 1,
  lifeFinance: 2,
  abroad: 4,
  delta: 8,
  deltaElementary: 16,
  productzia: 32,
}

export const insuranceField = {
  [insuranceFieldType.elementary]: {
    id: insuranceFieldType.elementary,
    name: 'אלמנטרי',
    nameEn: 'Elementary',
    iconPath: './assets/field-home/Home.png',
    activeIconPath: './assets/field-home/Home-active.svg',
    svgIcon: <HomeIconSvg />,
    insuranceCategories: [
      insuranceCategoryType.car,
      insuranceCategoryType.appartment,
      insuranceCategoryType.bussiness,
      insuranceCategoryType.otherE,
      insuranceCategoryType.liability,
    ],
    order: 2,
  },
  [insuranceFieldType.lifeFinance]: {
    id: insuranceFieldType.lifeFinance,
    name: 'חיים ופיננסי',
    nameEn: 'Life & finance',
    iconPath: './assets/field-heart/Heart.png',
    activeIconPath: './assets/field-heart/Heart-active.svg',
    svgIcon: <HeartIconSvg />,
    insuranceCategories: [
      insuranceCategoryType.risks,
      insuranceCategoryType.pension,
      insuranceCategoryType.financial,
      insuranceCategoryType.otherL,
      insuranceCategoryType.health,
      insuranceCategoryType.mortgage,
      insuranceCategoryType.workIncapacity,
      insuranceCategoryType.nursing,
      insuranceCategoryType.benefitFund,
    ],
    order: 1,
  },
  [insuranceFieldType.abroad]: {
    id: insuranceFieldType.abroad,
    name: 'חו"ל',
    nameEn: 'Abroad',
    iconPath: './assets/field-global/Public.png',
    activeIconPath: './assets/field-global/Public-active.svg',
    svgIcon: <AirportIconSvg />,
    insuranceCategories: [insuranceCategoryType.travel],
    order: 3,
  },
  [insuranceFieldType.delta]: {
    id: insuranceFieldType.delta,
    name: 'נתוני עמלות לסוכנות על סוכני המשנה',
    nameEn: 'Delta',
    iconPath: './assets/field-heart/Heart.png',
    activeIconPath: './assets/field-heart/Heart-active.svg',
    svgIcon: <ChangeHistory />,
    insuranceCategories: [],
    order: 4,
  },
  [insuranceFieldType.deltaElementary]: {
    id: insuranceFieldType.deltaElementary,
    name: 'סוכני משנה - אלמנטרי',
    nameEn: 'Delta - Elementary',
    iconPath: './assets/field-home/Home.png',
    activeIconPath: './assets/field-home/Home-active.svg',
    svgIcon: <HomeIconSvg />,
    insuranceCategories: [],
    order: 5,
  },
  [insuranceFieldType.productzia]: {
    id: insuranceFieldType.productzia,
    name: 'Productzia',
    nameEn: 'Productzia',
    iconPath: './assets/field-heart/Heart.png',
    activeIconPath: './assets/field-heart/Heart-active.svg',
    svgIcon: <HeartIconSvg />,
    insuranceCategories: [],
    order: 6,
  },
}

export const getInsuranceFields = () => Object.values(insuranceField).sort((a, b) => a.order - b.order)

export const getInsuranceFieldByCategory = categoryId =>
  Object.values(insuranceField).find(f => f.insuranceCategories.includes(categoryId))
