export const scanStatusType = {
  None: 0,
  Started: 1,
  InProgress: 2,
  WaitingSms: 3,
  SkanComplete: 4,
  Error: 5,
  WaitingSmsTimeOut: 6,
  Canceled: 7,
  LoginError: 8,
  AuthWaiting: 81,
  AuthWaitingTimeout: 82,
  EmptyLogin: 83,
  IncorrectSMSCode: 9,
  ReportNotExist: 10,
  ResetPasswordRequierd: 11,
  ProcessReports: 12,
  Completed: 13,
  CompletedWithError: 14,
  InvalidVaultCredentials: 15,
  ResetVaultPasswordRequired: 16,
  WaitingAnswer: 17,
  UnexpectedPage: 19,
  NoPermission: 20,
  Closed: 21,
  UserFrozen: 22,
}

export const scanStatus = {
  [scanStatusType.None]: {
    text: 'No status',
    textEn: 'No status',
  },
  [scanStatusType.Started]: {
    text: 'Scan Started',
    textEn: 'Scan Started',
  },
  [scanStatusType.InProgress]: {
    text: 'Scan is in Progress',
    textEn: 'Scan is in Progress',
  },
  [scanStatusType.WaitingSms]: {
    text: 'SMS Waiting',
    textEn: 'SMS Waiting',
  },
  [scanStatusType.SkanComplete]: {
    text: 'Scan Completed',
    textEn: 'Scan Completed',
  },
  [scanStatusType.Error]: {
    text: 'Scan Error',
    textEn: 'Scan Error',
  },
  [scanStatusType.WaitingSmsTimeOut]: {
    text: 'SMS Waiting TimeOut',
    textEn: 'SMS Waiting TimeOut',
  },
  [scanStatusType.Canceled]: {
    text: 'Scan Cancelled',
    textEn: 'Scan Cancelled',
  },
  [scanStatusType.LoginError]: {
    text: 'Login Error',
    textEn: 'Login Error',
  },
  [scanStatusType.AuthWaiting]: {
    text: 'Auth Waiting',
    textEn: 'Auth Waiting',
  },
  [scanStatusType.AuthWaitingTimeout]: {
    text: 'Auth Waiting Timeout',
    textEn: 'Auth Waiting Timeout',
  },
  [scanStatusType.EmptyLogin]: {
    text: 'No Login Data',
    textEn: 'No Login Data',
  },
  [scanStatusType.IncorrectSMSCode]: {
    text: 'SMS Waiting TimeOut',
    textEn: 'SMS Waiting TimeOut',
  },
  [scanStatusType.ReportNotExist]: {
    text: 'Report does not exist',
    textEn: 'Report does not exist',
  },
  [scanStatusType.ResetPasswordRequierd]: {
    text: 'Reset Pasword Required',
    textEn: 'Reset Pasword Required',
  },
  [scanStatusType.ProcessReports]: {
    text: 'Processing reports',
    textEn: 'Processing reports',
  },
  [scanStatusType.Completed]: {
    text: 'Scan Completed',
    textEn: 'Scan Completed',
  },
  [scanStatusType.CompletedWithError]: {
    text: 'Scan Completed with Error',
    textEn: 'Scan Completed with Error',
  },
  [scanStatusType.InvalidVaultCredentials]: {
    text: 'Invalid vault credentials',
    textEn: 'Invalid vault credentials',
  },
  [scanStatusType.UserFrozen]: {
    text: 'User Frozen',
    textEn: 'User Frozen',
  },
}
