import store from '../../app/store'
import { isDeltaAgency } from '../../features/login/reducers/loginSelectors'
import { deltaCommissionSplitView, familyGroupMode } from '../../features/shared/reducers/sharedSelectors'
import { deltaCommissionViewType } from '../../types/deltaCommissionViewTypes'
import { lowercaseFirstLetter } from '../../utils/charsUtils'
import { columnConfig, columnsConfigPage, columnType, pagesColumnsMap } from './columnsConfigTypes'

export const mapPagesColumns = columns => {
  for (const page in pagesColumnsMap) {
    if (columns[page]) {
      const mapedColumnNames = pagesColumnsMap[page].map(({ columnName }) => columnName)
      columns[page] = columns[page]
        .filter(c => mapedColumnNames.includes(c.columnName))
        .sort((a, b) => mapedColumnNames.indexOf(a.columnName) - mapedColumnNames.indexOf(b.columnName))
        .map((c, idx) => ({
          ...c,
          cssGridColumnWidth: pagesColumnsMap[page][idx].cssGridColumnWidth ?? '1fr',
          columnContent: pagesColumnsMap[page][idx].columnContent ?? columnConfig[c.columnName].columnContent,
        }))
    }
  }
  return columns
}

export const getAvailableColumns = pageKey => {
  const { columnsConfig } = store.getState().shared
  if (!columnsConfig || !columnsConfig[pageKey]) return []
  return columnsConfig[pageKey].filter(c => c.visible)
}

export const getAvailableColumnsToShow = pageKey => {
  let columns = getAvailableColumns(pageKey)
  const { commissionView } = store.getState().shared

  if (!isDeltaAgency() || commissionView === deltaCommissionViewType.agent) {
    columns = columns.filter(c => !(c.columnName.includes('Agency') || c.columnName.includes('BothTotal')))
  }
  if (
    !familyGroupMode() &&
    (pageKey === columnsConfigPage.customerElementary ||
      pageKey === columnsConfigPage.customerLife ||
      pageKey === columnsConfigPage.customerAbroad)
  ) {
    columns = columns.filter(
      c => c.columnName !== columnType.customerName && c.columnName !== columnType.customerIdNumber
    )
  }
  if (deltaCommissionSplitView()) {
    columns = columns.filter(c => !c.columnName.includes('BothTotal'))
  }
  if (isDeltaAgency() && commissionView === deltaCommissionViewType.agency) {
    columns = columns.filter(c => !(c.columnName.includes('Commission') && !c.columnName.includes('Agency')))
  }
  if (isDeltaAgency() && commissionView === deltaCommissionViewType.bothTogether) {
    columns = columns.filter(
      c =>
        !c.columnName.includes('Commission') ||
        (c.columnName.includes('Commission') && c.columnName.includes('BothTotal'))
    )
  }
  return columns
}

export const getColumnName = ({ columnName, page }, t, tKey = '') => {
  page = lowercaseFirstLetter(page)
  let name = columnName
  let nameSuffix = ''
  if (columnName.includes('Agency')) {
    name = columnName.replace('Agency', '')
    nameSuffix = ' ' + t('agencyPrefix')
  }
  if (columnName.includes('BothTotal')) {
    name = columnName.replace('BothTotal', '')
    nameSuffix = ' ' + t('agencyAgentPrefix')
  }
  if (columnName.includes('Commission') && !columnName.includes('Agency') && !columnName.includes('BothTotal')) {
    nameSuffix = ' ' + t('agentPrefix')
  }
  if (
    (page === columnsConfigPage.policiesElementary ||
      page === columnsConfigPage.policiesNewElementary ||
      page === columnsConfigPage.policiesLostElementary) &&
    !columnName.includes('Monthly') &&
    !columnName.includes('CommissionRate') &&
    (columnName.includes('Premium') || columnName.includes('Commission'))
  ) {
    name = name + 'LastMonth'
  }
  if (
    page === columnsConfigPage.customerElementary &&
    !columnName.includes('Monthly') &&
    !columnName.includes('CommissionRate') &&
    columnName.includes('Premium') &&
    !columnName.includes('Brutto')
  ) {
    name = name + 'Netto'
  }
  return t(`${tKey}.${lowercaseFirstLetter(name)}`) + (isDeltaAgency() ? nameSuffix : '')
}
