import { phoneCodes } from '../../../environment/phoneCodes'
import { getRandomInt } from '../../../services/getRandom'
import {
  SHARED_CATEGORIES_REQUEST,
  SHARED_CATEGORIES_SUCCESS,
  SHARED_CATEGORIES_FAIL,
  SHARED_AGENTS_REQUEST,
  SHARED_AGENTS_SUCCESS,
  SHARED_AGENTS_FAIL,
  SHARED_ROLES_REQUEST,
  SHARED_ROLES_SUCCESS,
  SHARED_ROLES_FAIL,
  SHARED_AGENTS_RESET,
  SHARED_CRAWLER_REQUEST,
  SHARED_CRAWLER_SUCCESS,
  SHARED_SET_NO_SUBSCRIPTION,
  SHARED_AGENT_NUMBERS_REQUEST,
  SHARED_AGENT_NUMBERS_SUCCESS,
  SHARED_AGENT_NUMBERS_FAIL,
  SHARED_ADD_GROUP_REQUEST,
  SHARED_ADD_GROUP_SUCCESS,
  SHARED_ADD_GROUP_FAIL,
  SHARED_ADD_GROUP_RESET,
  SHARED_AGENT_GROUPS_REQUEST,
  SHARED_AGENT_GROUPS_SUCCESS,
  SHARED_AGENT_GROUPS_FAIL,
  SHARED_AGENT_NUMBERS_RESET,
  SHARED_REMOVE_GROUP_REQUEST,
  SHARED_REMOVE_GROUP_SUCCESS,
  SHARED_REMOVE_GROUP_FAIL,
  SHARED_UPDATE_GROUP_REQUEST,
  SHARED_UPDATE_GROUP_SUCCESS,
  SHARED_UPDATE_GROUP_FAIL,
  SHARED_UPDATE_GROUP_RESET,
  SHARED_SELECT_GROUPS,
  SHARED_DESELECT_GROUPS,
  SHARED_ALL_AGENT_GROUPS_REQUEST,
  SHARED_ALL_AGENT_GROUPS_SUCCESS,
  SHARED_ALL_AGENT_GROUPS_FAIL,
  SHARED_AGENCY_INVITES_REQUEST,
  SHARED_AGENCY_INVITES_SUCCESS,
  SHARED_AGENCY_INVITES_FAIL,
  SHARED_AGENCY_INVITE_UPDATE,
  SHARED_SET_NO_PAYMENT,
  SHARED_AGENT_REMOVE_REQUEST,
  SHARED_AGENT_REMOVE_SUCCESS,
  SHARED_AGENT_REMOVE_FAIL,
  SHARED_AGENT_RESTORE_REQUEST,
  SHARED_AGENT_RESTORE_SUCCESS,
  SHARED_AGENT_RESTORE_FAIL,
  SHARED_SET_FAMILY_GROUPS_TYPE,
  SHARED_SET_COMMISSION_VIEW,
  SHARED_COLUMNS_CONFIG_SUCCESS,
  SHARED_COLUMNS_CONFIG_FAIL,
  SHARED_COLUMNS_CONFIG_REQUEST,
  SHARED_COLUMNS_CONFIG_UPDATE_SUCCESS,
  SHARED_COLUMNS_CONFIG_UPDATE_FAIL,
} from '../../../types/actionTypesShared'
import { actionWithAgentType } from '../../../types/actionWithAgentTypes'
import { familyGroupType } from '../../../types/familyGroupTypes'
import { deltaCommissionViewType } from '../../../types/deltaCommissionViewTypes'

const { areaCodes, countryCode } = phoneCodes.il

const initialState = {
  countryPhoneCode: countryCode,
  areaPhoneCodes: areaCodes,
  categories: [],
  categoriesLoading: true,
  agentsLoading: true,
  agents: [],
  agentNumbersLoading: true,
  activeAgents: [],
  deletedAgents: [],
  allAgentGroupsLoading: true,
  allAgentGroups: [],
  agentGroupsLoading: true,
  agentGroups: [],
  addGroupSuccess: false,
  addGroupLoading: false,
  removeGroupSuccess: false,
  updateGroupSuccess: false,
  selectedGroupsIds: [],
  rolesLoading: true,
  roles: [],
  crawlerCompanies: [],
  crawlerCompaniesLoading: true,
  noSubscriptionFound: false,
  noPayment: false,
  agencyInvitesLoading: true,
  agencyInvites: [],
  agencyInvitesError: null,
  familyGroupsType: familyGroupType.standalone,
  commissionView: deltaCommissionViewType.agent,
  columnsConfigLoading: true,
  columnsConfig: {},
  columnsConfigErr: null,
}

export const sharedReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case SHARED_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
        categories: [],
      }
    case SHARED_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesLoading: false,
        categories: action.payload,
      }
    case SHARED_CATEGORIES_FAIL:
      return {
        ...state,
        categoriesLoading: false,
        categories: [],
        categoriesError: action.payload,
      }
    case SHARED_AGENTS_REQUEST:
      return {
        ...state,
        agentsLoading: true,
        agents: [],
      }
    case SHARED_AGENTS_SUCCESS:
      return {
        ...state,
        agentsLoading: false,
        agents: action.payload,
      }
    case SHARED_AGENTS_FAIL:
      return {
        ...state,
        agentsLoading: false,
        agents: [],
        agentsError: action.payload,
      }
    case SHARED_AGENTS_RESET:
      return {
        ...state,
        agentsLoading: true,
        agents: [],
        agentsError: null,
      }
    case SHARED_ROLES_REQUEST:
      return {
        ...state,
        rolesLoading: true,
        roles: [],
      }
    case SHARED_ROLES_SUCCESS:
      return {
        ...state,
        rolesLoading: false,
        roles: action.payload,
      }
    case SHARED_ROLES_FAIL:
      return {
        ...state,
        rolesLoading: false,
        roles: [],
        rolesError: action.payload,
      }
    case SHARED_CRAWLER_REQUEST:
      return {
        ...state,
        crawlerCompaniesLoading: true,
        crawlerCompanies: [],
      }
    case SHARED_CRAWLER_SUCCESS:
      return {
        ...state,
        crawlerCompaniesLoading: false,
        crawlerCompanies: action.payload,
      }
    case SHARED_AGENT_NUMBERS_REQUEST:
      return {
        ...state,
        agentNumbersLoading: true,
        activeAgents: [],
        deletedAgents: [],
        agentNumbersError: null,
      }
    case SHARED_AGENT_NUMBERS_SUCCESS:
      return {
        ...state,
        agentNumbersLoading: false,
        ...action.payload,
      }
    case SHARED_AGENT_NUMBERS_FAIL:
      return {
        ...state,
        agentNumbersLoading: false,
        activeAgents: [],
        deletedAgents: [],
        agentNumbersError: action.payload,
      }
    case SHARED_AGENT_NUMBERS_RESET:
      return {
        ...state,
        agentNumbersLoading: true,
        activeAgents: [],
        deletedAgents: [],
        agentNumbersError: null,
      }
    case SHARED_AGENT_REMOVE_REQUEST: {
      const id = action.payload
      return {
        ...state,
        activeAgents: state.activeAgents.map(a => ({ ...a, loading: a.id === id ? true : a.loading })),
      }
    }
    case SHARED_AGENT_REMOVE_SUCCESS: {
      const id = action.payload
      return {
        ...state,
        activeAgents: state.activeAgents.filter(a => a.id !== id),
        deletedAgents: [
          ...state.deletedAgents,
          {
            ...state.activeAgents.find(a => a.id === id),
            loading: false,
            nextPaymentAction: actionWithAgentType.deactivate,
            isActive: false,
          },
        ],
      }
    }
    case SHARED_AGENT_REMOVE_FAIL: {
      const { id } = action.payload
      return {
        ...state,
        activeAgents: state.activeAgents.map(a => ({ ...a, loading: a.id === id ? false : a.loading })),
      }
    }
    case SHARED_AGENT_RESTORE_REQUEST: {
      const id = action.payload
      return {
        ...state,
        deletedAgents: state.deletedAgents.map(a => ({ ...a, loading: a.id === id ? true : a.loading })),
      }
    }
    case SHARED_AGENT_RESTORE_SUCCESS: {
      const id = action.payload
      return {
        ...state,
        deletedAgents: state.deletedAgents.filter(a => a.id !== id),
        activeAgents: [
          ...state.activeAgents,
          {
            ...state.deletedAgents.find(a => a.id === id),
            loading: false,
            nextPaymentAction: actionWithAgentType.activate,
            isActive: true,
          },
        ],
      }
    }
    case SHARED_AGENT_RESTORE_FAIL: {
      const { id } = action.payload
      return {
        ...state,
        deletedAgents: state.deletedAgents.map(a => ({ ...a, loading: a.id === id ? false : a.loading })),
      }
    }
    case SHARED_ALL_AGENT_GROUPS_REQUEST:
      return {
        ...state,
        allAgentGroupsLoading: true,
        allAgentGroups: [],
        allAgentGroupsError: null,
      }
    case SHARED_ALL_AGENT_GROUPS_SUCCESS:
      return {
        ...state,
        allAgentGroupsLoading: false,
        allAgentGroups: action.payload.map(gr => ({ ...gr, colorIndex: getRandomInt(1, 3) - 1 })),
      }
    case SHARED_ALL_AGENT_GROUPS_FAIL:
      return {
        ...state,
        allAgentGroupsLoading: false,
        allAgentGroups: [],
        allAgentGroupsError: action.payload,
      }
    case SHARED_AGENT_GROUPS_REQUEST:
      return {
        ...state,
        agentGroupsLoading: true,
        agentGroupsError: null,
      }
    case SHARED_AGENT_GROUPS_SUCCESS:
      return {
        ...state,
        agentGroupsLoading: false,
        agentGroups: action.payload.map(gr => ({
          ...gr,
          colorIndex: getRandomInt(1, 3) - 1,
          agents: gr.agents.map(a => ({ ...a, innerId: a.id + ' ' + a.type + ' ' + a.insuranceFieldId })),
        })),
      }
    case SHARED_AGENT_GROUPS_FAIL:
      return {
        ...state,
        agentGroupsLoading: false,
        agentGroups: [],
        agentGroupsError: action.payload,
      }
    case SHARED_ADD_GROUP_REQUEST:
      return {
        ...state,
        addGroupLoading: true,
        addGroupSuccess: false,
        addGroupError: null,
      }
    case SHARED_ADD_GROUP_SUCCESS: {
      const { success, group } = action.payload
      return {
        ...state,
        addGroupLoading: false,
        addGroupSuccess: success,
        addGroupError: null,
        ...(success && { agentGroups: [...state.agentGroups, { ...group, colorIndex: getRandomInt(1, 3) - 1 }] }),
      }
    }
    case SHARED_ADD_GROUP_FAIL:
      return {
        ...state,
        addGroupLoading: false,
        addGroupSuccess: false,
        addGroupError: action.payload,
      }
    case SHARED_ADD_GROUP_RESET:
      return {
        ...state,
        addGroupLoading: false,
        addGroupSuccess: false,
        addGroupError: null,
      }
    case SHARED_REMOVE_GROUP_REQUEST: {
      const id = action.payload
      return {
        ...state,
        agentGroups: state.agentGroups.map(g => ({
          ...g,
          loading: id === g.id,
        })),
        removeGroupSuccess: false,
        removeGroupError: null,
      }
    }
    case SHARED_REMOVE_GROUP_SUCCESS: {
      const { success, id } = action.payload
      return {
        ...state,
        removeGroupSuccess: success,
        removeGroupError: null,
        agentGroups: success
          ? state.agentGroups.filter(g => g.id !== id)
          : state.agentGroups.map(g => ({ ...g, loading: g.id === id ? false : g?.loading })),
        ...(success &&
          state.selectedGroupsIds.includes(id) && {
            selectedGroupsIds: state.selectedGroupsIds.filter(selectedId => selectedId !== id),
          }),
      }
    }
    case SHARED_REMOVE_GROUP_FAIL: {
      const { error, id } = action.payload
      return {
        ...state,
        removeGroupSuccess: false,
        removeGroupError: error,
        agentGroups: state.agentGroups.map(g => ({ ...g, loading: g.id === id ? false : g?.loading })),
      }
    }
    case SHARED_UPDATE_GROUP_REQUEST: {
      const id = action.payload
      return {
        ...state,
        agentGroups: state.agentGroups.map(g => ({
          ...g,
          loading: id === g.id,
        })),
        updateGroupSuccess: false,
        updateGroupError: null,
      }
    }
    case SHARED_UPDATE_GROUP_SUCCESS: {
      const { success, group } = action.payload
      return {
        ...state,
        updateGroupSuccess: success,
        updateGroupError: null,
        agentGroups: success
          ? state.agentGroups.map(g => (g.id === group.id ? group : g))
          : state.agentGroups.map(g => ({ ...g, loading: g.id === group.id ? false : g?.loading })),
      }
    }
    case SHARED_UPDATE_GROUP_FAIL: {
      const { error, id } = action.payload
      return {
        ...state,
        updateGroupSuccess: false,
        updateGroupError: error,
        agentGroups: state.agentGroups.map(g => ({ ...g, loading: g.id === id ? false : g?.loading })),
      }
    }
    case SHARED_UPDATE_GROUP_RESET: {
      return {
        ...state,
        updateGroupSuccess: false,
        updateGroupError: null,
        agentGroups: state.agentGroups.map(g => ({ ...g, loading: false })),
      }
    }
    case SHARED_SELECT_GROUPS: {
      return {
        ...state,
        selectedGroupsIds: action.payload.filter(selectedId =>
          state.agentGroups.map(({ id }) => id).includes(selectedId)
        ),
      }
    }
    case SHARED_DESELECT_GROUPS: {
      return {
        ...state,
        selectedGroupsIds: state.selectedGroupsIds.filter(id => !action.payload.includes(id)),
      }
    }
    case SHARED_SET_NO_SUBSCRIPTION:
      return {
        ...state,
        noSubscriptionFound: action.payload,
      }
    case SHARED_SET_NO_PAYMENT:
      return {
        ...state,
        noPayment: payload,
      }
    case SHARED_AGENCY_INVITES_REQUEST: {
      return {
        ...state,
        agencyInvitesLoading: true,
        agencyInvites: [],
        agencyInvitesError: null,
      }
    }
    case SHARED_AGENCY_INVITES_SUCCESS: {
      return {
        ...state,
        agencyInvitesLoading: false,
        agencyInvites: payload,
        agencyInvitesError: null,
      }
    }
    case SHARED_AGENCY_INVITES_FAIL: {
      return {
        ...state,
        agencyInvitesLoading: false,
        agencyInvitesError: payload,
      }
    }
    case SHARED_AGENCY_INVITE_UPDATE: {
      return {
        ...state,
        agencyInvites: state.agencyInvites.map(ai => (ai.id === payload.id ? payload : ai)),
      }
    }
    case SHARED_SET_FAMILY_GROUPS_TYPE: {
      return {
        ...state,
        familyGroupsType: payload,
      }
    }
    case SHARED_SET_COMMISSION_VIEW: {
      return {
        ...state,
        commissionView: payload,
      }
    }
    case SHARED_COLUMNS_CONFIG_REQUEST: {
      return { ...state, columnsConfig: {}, columnsConfigLoading: true, columnsConfigErr: null }
    }
    case SHARED_COLUMNS_CONFIG_SUCCESS: {
      return { ...state, columnsConfig: payload, columnsConfigLoading: false, columnsConfigErr: null }
    }
    case SHARED_COLUMNS_CONFIG_FAIL: {
      return { ...state, columnsConfig: {}, columnsConfigLoading: false, columnsConfigErr: payload }
    }
    case SHARED_COLUMNS_CONFIG_UPDATE_SUCCESS: {
      return { ...state, columnsConfig: payload, columnsConfigLoading: false, columnsConfigErr: null }
    }
    case SHARED_COLUMNS_CONFIG_UPDATE_FAIL: {
      return { ...state, columnsConfigErr: payload }
    }
    default:
      return state
  }
}
