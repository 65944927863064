import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import { incomesTotalsObject, incomesTotalsType } from '../../../../types/analyticsIncomesTypes'
import { getIncomesTotalsTypes } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AgencyHouseAnalyticsIncomesChart from '../AgencyHouseAnalyticsIncomesChart/AgencyHouseAnalyticsIncomesChart'
import AgencyHouseIncomesSplittedViewChart from '../AgencyHouseIncomesSplittedViewChart/AgencyHouseIncomesSplittedViewChart'
import ApexCharts from 'apexcharts'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import { commissionKeySuffix, deltaCommissionSplitView, isDeltaAgency } from '../../../shared/reducers/sharedSelectors'
import './AgencyHouseAnalyticsIncomesChartsContainer.scss'

const AgencyHouseAnalyticsIncomesChartsContainer = ({
  dataType,
  listType,
  periodViewType,
  list,
  listLoading,
  totals,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const deltaView =
    (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate) && isDeltaAgency()
  const deltaSingleView = deltaView && !deltaCommissionSplitView()
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly
  const notMonthlyAccView = dataType === requestDataTypes.accumulation && !monthlyView
  const totalsTypes = !notMonthlyAccView
    ? getIncomesTotalsTypes(dataType, periodViewType)
    : [incomesTotalsType.accumulation]
  const totalsTypesKeys = Object.fromEntries(
    Object.entries(incomesTotalsType)
      .filter(([k, v]) => totalsTypes.includes(v))
      .map(([k, v]) => [v, k])
  )

  const getMaximumFractionDigits = totalsType =>
    totalsType === incomesTotalsType.commissionRateAccumulation ||
    totalsType === incomesTotalsType.commissionRateAccumulationAgency ||
    totalsType === incomesTotalsType.commissionRateAccumulationBothTotal
      ? 4
      : 2

  const getSeriesKey = (totalsType, keySuffix = '') =>
    totalsTypesKeys[totalsType] + keySuffix + (deltaView ? commissionKeySuffix() : '')

  const getSeries = totalsType => {
    const seriesKey = getSeriesKey(totalsType)
    return list.filter(itm => itm[seriesKey]).map(itm => itm[seriesKey])
  }

  const getCategories = totalsType => {
    const seriesKey = getSeriesKey(totalsType)
    const seriesDiffKey = getSeriesKey(totalsType, 'Diff')
    return list
      .filter(itm => itm[seriesKey])
      .map(itm => [
        hebrew() ? itm.name : itm.nameEn,
        dataType === requestDataTypes.commissionRate
          ? formatAsPercent(itm[seriesKey], {
              maximumFractionDigits: getMaximumFractionDigits(totalsType),
            })
          : `₪${Intl.NumberFormat('en', { notation: itm[seriesKey] > 1000 ? 'compact' : 'standard' }).format(
              itm[seriesKey]
            )}`,
        itm[seriesDiffKey]
          ? `${itm[seriesDiffKey] > 0 ? '+' : '-'} ${
              dataType === requestDataTypes.commissionRate
                ? formatAsPercent(Math.abs(itm[seriesDiffKey]), {
                    maximumFractionDigits: getMaximumFractionDigits(totalsType),
                  })
                : `₪${Intl.NumberFormat('en', {
                    notation: Math.abs(itm[seriesDiffKey]) > 1000 ? 'compact' : 'standard',
                  }).format(Math.abs(itm[seriesDiffKey]))}`
            }
            ${itm[seriesDiffKey] > 0 ? '\u2191' : '\u2193'}`
          : '',
        itm[seriesDiffKey] < 0 ? 'red' : itm[seriesDiffKey] > 0 ? 'green' : '',
      ])
  }

  const getColors = totalsType => {
    const seriesKey = getSeriesKey(totalsType)
    return list
      .map((itm, index) => ({ ...itm, index }))
      .filter(itm => itm[seriesKey])
      .map(
        itm =>
          categoriesObjects[itm.id ?? itm.key]?.markerColor ??
          categoriesObjects[itm.index]?.markerColor ??
          categoriesObjects['default'].markerColor
      )
  }
  const getTotalsValue = totalsType => {
    const seriesKey = getSeriesKey(totalsType)
    const { value } = totals.find(t => t.typeKey === seriesKey)
    return (
      <>
        {t('total')}
        <span className='total-value'>
          {value
            ? dataType === requestDataTypes.commissionRate
              ? formatAsPercent(value, {
                  maximumFractionDigits: getMaximumFractionDigits(totalsType),
                })
              : '₪' + formatAsCurrency(value, { currency: '' })
            : '--'}
        </span>
      </>
    )
  }

  const selectItemHandler =
    totalsType =>
    (idx, mainKeySuffix = null) => {
      const seriesKey = getSeriesKey(totalsType)
      const keySuffix = mainKeySuffix || (deltaSingleView ? commissionKeySuffix() : '')
      const diffKey = totalsTypesKeys[totalsType]
      const filteredList = list.filter(itm => deltaCommissionSplitView() || itm[seriesKey])
      filteredList[idx] && selectItem({ key: filteredList[idx].key, type: listType, diffKey, mainKeySuffix: keySuffix })
    }

  useEffect(() => {
    totalsTypes.forEach(tt => {
      let chart
      try {
        chart = ApexCharts.getChartByID(`incomes-chart-total-type-${tt}`)
      } catch (error) {
        chart = null
      }

      if (false) {
        //chart) {
        const selectedDataPoints = chart.w.globals.selectedDataPoints
        const hasSelectedDataPoints = selectedDataPoints?.length > 0 && selectedDataPoints[0]?.length > 0
        const singleBarChart =
          selectedDataPoints?.length && selectedDataPoints?.length === 1 && !deltaCommissionSplitView()
        if (singleBarChart) {
          if (selectedItem) {
            const selectedItemIndex = list
              .filter(itm => itm[totalsTypesKeys[tt]])
              .findIndex(itm => itm.key === selectedItem.key && totalsTypesKeys[tt] === selectedItem.diffKey)
            // if chart has selected data points, but the point is not in the list, deselect it
            if (selectedItemIndex === -1 && hasSelectedDataPoints) {
              // console.log(`Has selected item, char has selcted data point out of list, - DESELECT CHART ID ${tt}`)
              chart.toggleDataPointSelection(0, chart.w.globals.selectedDataPoints[0][0])
            }
            // if point in the list, but no data points selected, or incorrect points selcted, reselect
            if (
              selectedItemIndex > -1 &&
              (selectedDataPoints.length === 0 || !selectedDataPoints[0]?.includes(selectedItemIndex))
            ) {
              // console.log(
              //   `Has selected item, no data points, or incorrect data points selected - SELECT INDEX ${selectedItemIndex} CHART ID ${tt}, selectedDataPoints length - ${selectedDataPoints.length}`
              // )
              chart.toggleDataPointSelection(0, selectedItemIndex)
            }
          }
          if (!selectedItem && hasSelectedDataPoints) {
            // console.log(`no selected item, but chart has selected data point - DESELECT CHART ID ${tt}`)
            chart.toggleDataPointSelection(0, chart.w.globals.selectedDataPoints[0][0])
          }
        }
      }
    })
  }, [selectedItem])

  if (listLoading) {
    return <Loader />
  }

  return (
    <div className='agency-house-analytics-incomes-charts-container'>
      {!notMonthlyAccView &&
        totalsTypes.map(tt =>
          (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate) &&
          deltaCommissionSplitView() ? (
            <AgencyHouseIncomesSplittedViewChart
              key={tt}
              chartId={`incomes-chart-total-type-${tt}`}
              property={totalsTypesKeys[tt]}
              list={list}
              selectItem={selectItemHandler(tt)}
              totalsName={
                periodViewType === analyticsPeriodViewType.monthly
                  ? hebrew()
                    ? incomesTotalsObject[tt].nameMonthlyView
                    : incomesTotalsObject[tt].nameEnMonthlyView
                  : hebrew()
                  ? incomesTotalsObject[tt].name
                  : incomesTotalsObject[tt].nameEn
              }
              valuePrefix={dataType === requestDataTypes.commission ? '₪' : ''}
              valueSuffix={dataType === requestDataTypes.commissionRate ? '%' : ''}
              maximumFractionDigits={getMaximumFractionDigits(tt)}
              highlightBothBars={periodViewType === analyticsPeriodViewType.monthly}
            />
          ) : (
            <AgencyHouseAnalyticsIncomesChart
              key={tt}
              totalsName={
                periodViewType === analyticsPeriodViewType.monthly
                  ? hebrew()
                    ? incomesTotalsObject[tt].nameMonthlyView
                    : incomesTotalsObject[tt].nameEnMonthlyView
                  : hebrew()
                  ? incomesTotalsObject[tt].name
                  : incomesTotalsObject[tt].nameEn
              }
              totalsValue={getTotalsValue(tt)}
              series={getSeries(tt)}
              categories={getCategories(tt)}
              colors={getColors(tt)}
              selectItem={selectItemHandler(tt)}
              selectedItem={selectedItem}
              chartId={`incomes-chart-total-type-${tt}`}
            />
          )
        )}
      {notMonthlyAccView && (
        <AgencyHouseAnalyticsIncomesChart
          key={incomesTotalsType.accumulation}
          totalsName={
            periodViewType === analyticsPeriodViewType.monthly
              ? hebrew()
                ? incomesTotalsObject[incomesTotalsType.accumulation].nameMonthlyView
                : incomesTotalsObject[incomesTotalsType.accumulation].nameEnMonthlyView
              : hebrew()
              ? incomesTotalsObject[incomesTotalsType.accumulation].name
              : incomesTotalsObject[incomesTotalsType.accumulation].nameEn
          }
          totalsValue={getTotalsValue(incomesTotalsType.accumulation)}
          series={getSeries(incomesTotalsType.accumulation)}
          categories={getCategories(incomesTotalsType.accumulation)}
          colors={getColors(incomesTotalsType.accumulation)}
          selectItem={selectItemHandler(incomesTotalsType.accumulation)}
          selectedItem={selectedItem}
          chartId={`incomes-chart-total-type-${incomesTotalsType.accumulation}`}
        />
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesChartsContainer
