import { Fragment, useState } from 'react'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatusType } from '../../../../types/policyStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import { Tooltip } from '@mui/material'
import './PoliciesSignalsListItem.scss'

const PoliciesSignalsListItem = ({ signal, classes, selectIrreg, selected, styles }) => {
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  const availableColumns = getAvailableColumnsToShow(columnsConfigPage.irregularPolicy)

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-signals-list-item', {
          'has-policy-number': availableColumns.map(c => c.columnName).includes(columnType.policyNumber),
        })}
        onClick={() => setShowPolicyCard(true)}
        selectable
        onSelect={() => selectIrreg(signal.irregularityId)}
        selected={selected}
        styles={styles}
      >
        {availableColumns.map(c => (
          <Fragment key={c.columnName}>
            {c.columnName === columnType.policyNumber ? (
              <Tooltip
                title={signal.policyNumber}
                classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                arrow
                placement='top'
              >
                <div className='signal-policy-number'>{signal.policyNumber}</div>
              </Tooltip>
            ) : (
              columnConfig[c.columnName].columnContent(signal)
            )}
          </Fragment>
        ))}
        <div className='signal-badges'>
          <PolicyStatusChip status={policyStatusType.irregular} />
          {!signal.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
        </div>
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={signal.policyId} />}
    </>
  )
}

export default PoliciesSignalsListItem
