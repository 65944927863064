import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalWindow from '../ModalWindow/ModalWindow'
import SearchAgentsInput from './SearchAgentsInput/SearchAgentsInput'
import Button from '../Common/Button/Button'
import {
  addAgentGroup,
  removeAgentNumber,
  restoreAgentNumber,
  updateAgentGroup,
} from '../../features/shared/actions/sharedActions'
import Loader from '../Common/Loader/Loader'
import { hebrew } from '../../i18n'
import { getUserNameInitials } from '../../services/getUserNameInitials'
import { getRandomInt } from '../../services/getRandom'
import CrossMark from '../Common/Marks/CrossMark/CrossMark'
import InputText from '../Common/InputText/InputText'
import { SHARED_ADD_GROUP_RESET, SHARED_UPDATE_GROUP_RESET } from '../../types/actionTypesShared'
import NoData from '../Common/NoData/NoData'
import { avatarStyles } from '../../types/colorsTypes'
import LoadingOverlay from '../Common/LoadingOverlay/LoadingOverlay'
import ConfirmWarnDialog from '../ConfirmWarnDialog/ConfirmWarnDialog'
import { actionWithAgentType } from '../../types/actionWithAgentTypes'
import { ArrowDropDown, ArrowDropUp, Cancel, DeleteOutline, RestoreFromTrashOutlined } from '@mui/icons-material'
import { cn } from '../../utils/stylesUtils'
import { isDeltaAgency } from '../../features/shared/reducers/sharedSelectors'
import { formatAsShortDate } from '../../utils/formatAs'
import { insuranceField, insuranceFieldType } from '../../types/insuranceField'
import AgencyHouseAnalyticsFiltersPicker from '../../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsFiltersPicker/AgencyHouseAnalyticsFiltersPicker'
import CalendarBase from '../Common/CalendarBase/CalendarBase'
import RelativeFixedPosition from '../Common/RelativeFixedPosition/RelativeFixedPosition'
import TabsNavbar from '../TabsNavbar/TabsNavbar'
import { getFilteredAgentNumbersRequest } from '../../http/requests/agentGroupsRequests'
import useDebounce from '../../hooks/useDebounce'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { UTCDateToShortLocale } from '../../utils/date'
import { getMasterCompaniesRequest } from '../../http/masterRequests/companyRequests'
import './AddAgentGroupModal.scss'

const agentNumberType = {
  agent: 1,
  agency: 2,
}

const insuranceFieldsFilterInit = [
  insuranceField[insuranceFieldType.lifeFinance],
  insuranceField[insuranceFieldType.elementary],
  insuranceField[insuranceFieldType.abroad],
]

const agentTypesFilterInit = [
  {
    id: agentNumberType.agency,
    name: 'סוכנות',
    nameEn: 'agency',
  },
  {
    id: agentNumberType.agent,
    name: 'סוכן',
    nameEn: 'agent',
  },
]

const getAppliedFilters = filters => filters.filter(f => f.selected).map(f => f.id)

const agentsListType = {
  active: 1,
  deleted: 2,
}

const AGENTS_PAGE_SIZE = 20

const AddAgentGroupModal = ({
  closeModal,
  group = null,
  onAddUpdate = () => {
    console.log('Successfully Added or Updated')
  },
}) => {
  const { t } = useTranslation('profile')
  const [agents, setAgents] = useState([])
  const [agentsLoading, setAgentsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [currentAgentsList, setCurrentAgentsList] = useState(agentsListType.active)
  const [searchAgent, setSearchAgent] = useState('')
  const [groupName, setGroupName] = useState(group ? group.name : '')
  const [selectedAgents, setSelectedAgents] = useState(group ? group.agents : [])

  const [companiesFilter, setCompaniesFilter] = useState([])
  const [fieldsFilter, setFieldsFilter] = useState(insuranceFieldsFilterInit)
  const [agentTypesFilter, setAgentTypesFilter] = useState(agentTypesFilterInit)
  const [datesFilter, setDatesFilter] = useState()

  const { addGroupLoading, addGroupSuccess, updateGroupSuccess, agentGroups } = useSelector(({ shared }) => shared)
  const groupToUpdate = agentGroups.find(gr => gr.id === group?.id)
  const updateGroupLoading = groupToUpdate && groupToUpdate.loading

  const debouncedSearchText = useDebounce(searchAgent, 500)
  const activeAgentsList = currentAgentsList === agentsListType.active

  const applyFiltersHandler = setFilters => filterValue => {
    setPage(1)
    setFilters(filterValue)
  }

  const scrollRef = useBottomScrollListener(
    () => {
      if (!agentsLoading) {
        setPage(prev => prev + 1)
      }
    },
    // to resolve not scrolling issue on custom zoom
    { offset: 10 }
  )

  const dispatch = useDispatch()

  const submitBtnDisabled =
    updateGroupLoading ||
    agentsLoading ||
    addGroupLoading ||
    agents.length === 0 ||
    selectedAgents.length === 0 ||
    groupName.length === 0

  const selectAgentHandler = agent => {
    setSelectedAgents(prev =>
      prev.map(a => a.innerId).includes(agent.innerId)
        ? prev.filter(an => an.innerId !== agent.innerId)
        : [...prev, agent]
    )
  }

  const selectAllAgentsHandler = () => {
    // deselect if all are selected
    if (agents.every(({ innerId }) => selectedAgents.map(fa => fa.innerId).includes(innerId))) {
      setSelectedAgents(prev => prev.filter(an => !agents.map(fa => fa.innerId).includes(an.innerId)))
    } else {
      //select all if some are not
      const notIncluded = agents.filter(({ innerId }) => !selectedAgents.map(fa => fa.innerId).includes(innerId))
      setSelectedAgents(prev => [...prev, ...notIncluded])
    }
  }

  const addGroupHandler = () => {
    const groupDto = {
      name: groupName,
      agents: selectedAgents.map(a => ({
        ...a,
        id: a.id,
        type: a.type,
        insuranceFieldId: a.insuranceFieldId,
        innerId: a.id + ' ' + a.type + ' ' + a.insuranceFieldId,
      })),
    }
    dispatch(
      group
        ? updateAgentGroup({
            id: group.id,
            ...groupDto,
          })
        : addAgentGroup({
            ...groupDto,
          })
    )
  }

  useEffect(() => {
    if ((addGroupSuccess && !addGroupLoading) || (group && updateGroupSuccess && !updateGroupLoading)) {
      closeModal()
      onAddUpdate()
    }
    return () => {
      addGroupSuccess && dispatch({ type: SHARED_ADD_GROUP_RESET })
      group && updateGroupSuccess && dispatch({ type: SHARED_UPDATE_GROUP_RESET })
    }
  }, [
    addGroupSuccess,
    addGroupLoading,
    updateGroupSuccess,
    group,
    updateGroupLoading,
    closeModal,
    dispatch,
    onAddUpdate,
  ])

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const {
          data: { Data: data },
        } = await getMasterCompaniesRequest()
        setCompaniesFilter(data.map(c => ({ id: c.Id, name: c.Name, nameEn: c.EnglishName })))
      } catch (error) {}
    }
    fetchCompanies()
  }, [])

  const fetchAgents = useCallback(async () => {
    if (companiesFilter.length === 0) {
      return
    }

    const companies = getAppliedFilters(companiesFilter)
    const entityTypes = getAppliedFilters(agentTypesFilter)
    const fields = getAppliedFilters(fieldsFilter)
    const monthAdded = UTCDateToShortLocale(datesFilter)

    const requestModel = {
      page,
      pageSize: AGENTS_PAGE_SIZE,
      sortBy: 'agentNumber',
      descending: true,
      entityTypes,
      fields,
      companies,
      search: debouncedSearchText.toUpperCase(),
      deleted: !activeAgentsList,
      monthAdded,
    }
    try {
      setAgentsLoading(true)
      page === 1 && setAgents([])
      const {
        data: {
          data: { items },
        },
      } = await getFilteredAgentNumbersRequest(requestModel)
      setAgents(prev => [
        ...prev,
        ...items.map(d => ({ ...d, innerId: d.id + ' ' + d.type + ' ' + d.insuranceFieldId })),
      ])
      setAgentsLoading(false)
    } catch (error) {
      setAgentsLoading(false)
      console.log('Got Err')
      console.log(error)
    }
  }, [companiesFilter, agentTypesFilter, datesFilter, fieldsFilter, debouncedSearchText, page, activeAgentsList])

  useEffect(() => {
    fetchAgents()
  }, [fetchAgents])

  useEffect(() => {
    setPage(1)
  }, [activeAgentsList, debouncedSearchText])

  return (
    <ModalWindow
      closeOnOutsideClick={false}
      closeModal={closeModal}
      renderPortal
      showCloseIcon={false}
      loadingOverlay={addGroupLoading || updateGroupLoading}
    >
      <div className='add-agent-group-modal'>
        <header className='add-agent-group-modal-header'>
          <div>{t('addGroupModal.title')}</div>
          <button onClick={closeModal}>
            <img src='./assets/close-icon-square/close-square.png' alt='cls' />
          </button>
        </header>
        <div className='add-agent-group-modal-content'>
          <div className='add-agent-group-modal-sidebar'>
            <InputText
              value={groupName}
              onChange={setGroupName}
              required={true}
              label={t('addGroupModal.namePlaceholder')}
            />
            <div className='add-agent-group-modal-selected-heading'>{t('addGroupModal.agentNumbers')}</div>
            <div className='add-agent-group-modal-selected-items'>
              {selectedAgents.map(an => (
                <div className='add-agent-group-modal-selected-item-wrapper' key={an.innerId}>
                  <div className='add-agent-group-modal-selected-item'>
                    <div className='add-agent-group-modal-selected-item-main'>
                      <div className='add-agent-group-modal-company-logo'>
                        <img src={`./assets/companies-logos-light/${an.companyId}.png`} alt={an.companyNameEn} />
                      </div>
                      <div>{an.agentNumber}</div>
                    </div>
                    {isDeltaAgency() && an.type && <p>{t(`agentGroupListHeading.agentType${an.type}`)}</p>}
                    {an.insuranceFieldId && <p>{an[`fieldName${hebrew() ? '' : 'En'}`]}</p>}
                  </div>
                  <button onClick={() => selectAgentHandler(an)}>
                    <CrossMark />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className='add-agent-group-modal-main'>
            <div className='add-agent-group-modal-main-content'>
              <div className='add-agent-group-search-container'>
                <SearchAgentsInput
                  searchValue={searchAgent}
                  setSearchValue={setSearchAgent}
                  placeholder={t('addGroupModal.searchPlaceholder')}
                />
              </div>
              <div className='add-agent-group-modal-lists-container'>
                <TabsNavbar
                  items={[
                    { id: agentsListType.active, text: t('agentGroupListHeading.active') },
                    { id: agentsListType.deleted, text: t('agentGroupListHeading.deleted') },
                  ]}
                  currentItem={currentAgentsList}
                  setCurrentItem={setCurrentAgentsList}
                />
                <AgentsList
                  list={agents}
                  selectItem={selectAgentHandler}
                  selectedItems={selectedAgents}
                  selectAllItmes={selectAllAgentsHandler}
                  companiesFilter={companiesFilter}
                  setCompaniesFilter={applyFiltersHandler(setCompaniesFilter)}
                  fieldsFilter={fieldsFilter}
                  setFieldsFilter={applyFiltersHandler(setFieldsFilter)}
                  agentTypesFilter={agentTypesFilter}
                  setAgentTypesFilter={applyFiltersHandler(setAgentTypesFilter)}
                  datesFilter={datesFilter}
                  setDatesFilter={applyFiltersHandler(setDatesFilter)}
                  listLoading={agentsLoading}
                  scrollRef={scrollRef}
                />
              </div>
            </div>
            <footer className='add-agent-group-modal-footer'>
              <Button caption={t('addGroupModal.saveBtn')} disabled={submitBtnDisabled} onClick={addGroupHandler} />
            </footer>
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default AddAgentGroupModal

const AgentsList = ({
  list,
  listLoading,
  selectedItems,
  selectItem,
  selectAllItmes,
  datesFilter,
  setDatesFilter,
  companiesFilter,
  setCompaniesFilter,
  fieldsFilter,
  setFieldsFilter,
  agentTypesFilter,
  setAgentTypesFilter,
  scrollRef,
}) => {
  const { t } = useTranslation('profile')
  const [agentToRemove, setAgentToRemove] = useState(null)
  const [agentToRestore, setAgentToRestore] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const dateBtnRef = useRef()

  const dispatch = useDispatch()

  const removeAgent = () => {
    dispatch(removeAgentNumber(agentToRemove))
    setAgentToRemove(null)
  }
  const restoreAgent = () => {
    dispatch(restoreAgentNumber(agentToRestore))
    setAgentToRestore(null)
  }

  const applyFilters = setter => ids => setter(prev => prev.map(c => ({ ...c, selected: ids.includes(c.id) })))

  const setDatesFilterHandler = date => {
    setDatesFilter(date)
    setShowCalendar(false)
  }

  const actionButton = agent => {
    const { isActive, nextPaymentAction } = agent

    const agentIsActive =
      (!isActive && nextPaymentAction === actionWithAgentType.activate) ||
      (isActive && nextPaymentAction !== actionWithAgentType.deactivate)

    const clickHanlder = agentIsActive ? setAgentToRemove : setAgentToRestore
    return (
      <button onClick={() => clickHanlder(agent)}>
        {agentIsActive ? <DeleteOutline /> : <RestoreFromTrashOutlined style={{ color: 'var(--trend-up-color)' }} />}
      </button>
    )
  }

  return (
    <div className='add-agent-group-modal-list-container'>
      <div
        className={cn('add-agent-group-modal-list-heading add-agent-group-modal-list-grid', {
          extended: isDeltaAgency(),
        })}
      >
        <div>
          {list.length > 0 && (
            <button onClick={selectAllItmes} disabled={!selectItem}>
              <img
                src={`./assets/${
                  list.every(({ innerId }) => selectedItems.map(fa => fa.innerId).includes(innerId))
                    ? 'checkbox-sm-checked/Square.png'
                    : 'check-box-blank/Check Box Blank.png'
                }`}
                alt='slct'
              />
            </button>
          )}
        </div>
        <div>{t('agentGroupListHeading.agentNumber')}</div>
        <div>{t('agentGroupListHeading.agentName')}</div>
        {isDeltaAgency() && (
          <AgencyHouseAnalyticsFiltersPicker
            applyFilters={applyFilters(setAgentTypesFilter)}
            filters={agentTypesFilter}
            filtersLoading={false}
            filtersTitle={t(`agentGroupListHeading.agentType`)}
            noFiltersText={'No Types'}
            noFoundText={'Nothing Found'}
            searchPlaceholder={t(`addGroupModal.defaultSearch`)}
            showSelectedValues={false}
            enableClear
          />
        )}
        <AgencyHouseAnalyticsFiltersPicker
          applyFilters={applyFilters(setFieldsFilter)}
          filters={fieldsFilter}
          filtersLoading={false}
          filtersTitle={t(`agentGroupListHeading.agentField`)}
          noFiltersText={'No Fields'}
          noFoundText={'Nothing Found'}
          searchPlaceholder={t(`addGroupModal.defaultSearch`)}
          showSelectedValues={false}
          enableClear
        />
        <AgencyHouseAnalyticsFiltersPicker
          applyFilters={applyFilters(setCompaniesFilter)}
          filters={companiesFilter}
          filtersLoading={false}
          filtersTitle={t(`agentGroupListHeading.company`)}
          noFiltersText={'No Companies'}
          noFoundText={'Nothing Found'}
          searchPlaceholder={t(`addGroupModal.defaultSearch`)}
          showSelectedValues={false}
          enableClear
        />
        <div
          onClick={() => setShowCalendar(true)}
          ref={dateBtnRef}
          className={cn('add-agent-group-modal-list-heading-btn-container', { applied: datesFilter })}
        >
          <span>{t('agentGroupListHeading.agentAdded')}</span>
          {datesFilter ? (
            <button
              onClick={e => {
                e.stopPropagation()
                setDatesFilter()
              }}
            >
              <Cancel />
            </button>
          ) : showCalendar ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
        {showCalendar && (
          <RelativeFixedPosition anchorRef={dateBtnRef} onOverlayClick={() => setShowCalendar(false)}>
            <CalendarBase view='year' date={datesFilter} setDate={setDatesFilterHandler} />
          </RelativeFixedPosition>
        )}
        <div></div>
      </div>
      {list.length > 0 && (
        <div className='add-agent-group-modal-list' ref={scrollRef}>
          {list.map(an => (
            <div
              key={an.innerId}
              className={cn('add-agent-group-modal-list-item add-agent-group-modal-list-grid', {
                extended: isDeltaAgency(),
              })}
            >
              {an.loading && <LoadingOverlay />}
              <div>
                <button onClick={() => selectItem(an)} disabled={!selectItem}>
                  <img
                    src={`./assets/${
                      selectedItems.map(a => a.innerId).includes(an.innerId)
                        ? 'checkbox-sm-checked/Square.png'
                        : 'check-box-blank/Check Box Blank.png'
                    }`}
                    alt='slct'
                  />
                </button>
              </div>
              <div>{an.agentNumber}</div>
              <div className='add-agent-group-modal-list-item-name'>
                <div
                  className='add-agent-group-modal-list-item-avatar'
                  style={avatarStyles[an?.colorIndex ?? getRandomInt(1, 3) - 1]}
                >
                  {getUserNameInitials(an.name)}
                </div>
                <div>{an.name}</div>
              </div>
              {isDeltaAgency() && <div>{an.type ? t(`agentGroupListHeading.agentType${an.type}`) : '--'}</div>}
              <div>{an?.[`fieldName${hebrew() ? '' : 'En'}`] ?? '--'}</div>
              <div className='add-agent-group-modal-list-item-company'>
                <div className='add-agent-group-modal-company-logo'>
                  <img src={`./assets/companies-logos-light/${an.companyId}.png`} alt={an.companyNameEn} />
                </div>
                <div>{hebrew() ? an.companyName : an.companyNameEn}</div>
              </div>
              <div>{formatAsShortDate(an.added, '.', { noValue: '--' })}</div>
              {actionButton(an)}
            </div>
          ))}
        </div>
      )}
      {listLoading && <Loader />}
      {!listLoading && list.length === 0 && (
        <div className='add-agent-group-modal-no-data-container'>
          <NoData text='Nothing Found' />
        </div>
      )}
      {agentToRemove && (
        <ConfirmWarnDialog
          title={t(`deleteAgentWarnTitle`)}
          text={t(`delete${agentToRemove.deletedNextMonth ? 'Existing' : 'New'}AgentWarnText`)}
          cancelText={t('cancelAgentBtn')}
          confirmText={t('deleteAgentBtn')}
          cancelHandler={() => setAgentToRemove(null)}
          confirmHandler={removeAgent}
          dangerWarn
        />
      )}
      {agentToRestore && (
        <ConfirmWarnDialog
          title={t(`restoreAgentWarnTitle`)}
          text={t(`restoreAgentWarnText`)}
          cancelText={t('cancelAgentBtn')}
          confirmText={t('restoreAgentBtn')}
          cancelHandler={() => setAgentToRestore(null)}
          confirmHandler={restoreAgent}
        />
      )}
    </div>
  )
}
