import { useTranslation } from 'react-i18next'
import NoData from '../../../../components/Common/NoData/NoData'
import { formatAsCurrency } from '../../../../utils/formatAs'
import { Fragment, useEffect, useRef, useState } from 'react'
import { cn } from '../../../../utils/stylesUtils'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import PolicyCard from '../../../policies/components/PolicyCard/PolicyCard'
import PoliciesSyncIcon from '../../../policies/components/PoliciesSyncIcon/PoliciesSyncIcon'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow, getColumnName } from '../../../../services/columnsConfig/columnsConfigHelpers'
import ColumnsPicker from '../../../../components/ColumnsPicker/ColumnsPicker'
import { insuranceFieldType } from '../../../../types/insuranceField'
import './CustomerPoliciesList.scss'

const CustomerPoliciesList = ({ policies = [], policiesTotals, fieldId }) => {
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const { t } = useTranslation('customer')
  const listRef = useRef(null)

  useEffect(() => {
    listRef.current.scrollIntoView()
  }, [])

  if (!policies || policies.length === 0) {
    return (
      <div className='customer-policies-list' ref={listRef}>
        <NoData text='No Data' />
      </div>
    )
  }

  let columnConfigPage
  if (fieldId === insuranceFieldType.elementary) {
    columnConfigPage = columnsConfigPage.customerElementary
  }
  if (fieldId === insuranceFieldType.lifeFinance) {
    columnConfigPage = columnsConfigPage.customerLife
  }
  if (fieldId === insuranceFieldType.abroad) {
    columnConfigPage = columnsConfigPage.customerAbroad
  }
  const availableColumns = getAvailableColumnsToShow(columnConfigPage)
  const availableColumnsNames = availableColumns.map(c => c.columnName)

  const columnsGridStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${availableColumns.length === 0 ? 1 : availableColumns.length}, 1fr) ${
      availableColumns.length > 0 ? '42px 0.5fr' : ''
    }`,
  }

  return (
    <>
      <section className='customer-policies-list-container'>
        <header className={cn('customer-policies-list-grid')} style={columnsGridStyles}>
          {availableColumns.map(c => (
            <p key={c.columnName}>{getColumnName(c, t, 'policiesListHeadings')}</p>
          ))}
          <div></div>
          <div>
            <ColumnsPicker listKey={columnConfigPage} t={t} translationKey={'policiesListHeadings'} />
          </div>
        </header>
        <div className='customer-policies-list' ref={listRef}>
          {availableColumns.length === 0 && <NoData text={t('noColumnsSelected')} />}
          {availableColumns.length > 0 &&
            policies.map(p => (
              <div
                className={cn('customer-policies-list-item customer-policies-list-grid', {
                  'out-dated': p.outOfDate,
                  inactive: p.isNotActive,
                })}
                key={p.id}
                onClick={() => setSelectedPolicy(p.id)}
                style={columnsGridStyles}
              >
                {availableColumns.map(c => (
                  <Fragment key={c.columnName}>{columnConfig[c.columnName].columnContent(p)}</Fragment>
                ))}
                <div>
                  <PoliciesSyncIcon tooltip={t('tooltips.outdatedPolicy')} enabled={p.outOfDate} />
                </div>
                <div>
                  <div className='customer-policies-list-item-badges-wrapper'>
                    {p.isNotActive && <EntityStatusChip status={entityStatusType.inactive} />}
                    {p.isIrregular && <EntityStatusChip status={entityStatusType.irregular} />}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {availableColumns.length > 0 && (
          <div
            className={cn('customer-policies-list-item-totals customer-policies-list-grid', {})}
            style={columnsGridStyles}
          >
            <p className='totals-main-cell'>{t('policiesListHeadings.total')}</p>
            {availableColumnsNames.includes(columnType.policyNumber) && <p></p>}
            {availableColumnsNames.includes(columnType.customerIdNumber) && <p></p>}
            {availableColumnsNames.includes(columnType.customerName) && <p></p>}
            {availableColumnsNames.includes(columnType.employerName) && <p></p>}
            {availableColumnsNames.includes(columnType.employerNumber) && <p></p>}
            {availableColumnsNames.includes(columnType.company) && <p></p>}
            {availableColumnsNames.includes(columnType.category) && <p></p>}
            {availableColumnsNames.includes(columnType.premium) && <p>{formatAsCurrency(policiesTotals.premium)}</p>}
            {availableColumnsNames.includes(columnType.premiumBrutto) && (
              <p>{formatAsCurrency(policiesTotals.premiumBrutto)}</p>
            )}
            {availableColumnsNames.includes(columnType.fees) && <p>{formatAsCurrency(policiesTotals.fees)}</p>}
            {availableColumnsNames.includes(columnType.commissionAgency) && (
              <p>{formatAsCurrency(policiesTotals.commissionAgency, { dashesForZero: false })}</p>
            )}
            {availableColumnsNames.includes(columnType.commission) && (
              <p>{formatAsCurrency(policiesTotals.commission, { dashesForZero: false })}</p>
            )}
            {availableColumnsNames.includes(columnType.commissionBothTotal) && (
              <p>{formatAsCurrency(policiesTotals.commissionBothTotal, { dashesForZero: false })}</p>
            )}
            {availableColumnsNames.includes(columnType.premiumMonthly) && (
              <p>{formatAsCurrency(policiesTotals.premiumMonthly)}</p>
            )}
            {availableColumnsNames.includes(columnType.premiumBruttoMonthly) && (
              <p>{formatAsCurrency(policiesTotals.premiumBruttoMonthly)}</p>
            )}
            {availableColumnsNames.includes(columnType.accumulation) && (
              <p>{formatAsCurrency(policiesTotals.accumulation, { dashesForZero: false })}</p>
            )}
            {availableColumnsNames.includes(columnType.commissionRatePremiumAgency) && <p></p>}
            {availableColumnsNames.includes(columnType.commissionRatePremium) && <p></p>}
            {availableColumnsNames.includes(columnType.commissionRatePremiumBothTotal) && <p></p>}
            {availableColumnsNames.includes(columnType.commissionRateAccumulationAgency) && <p></p>}
            {availableColumnsNames.includes(columnType.commissionRateAccumulation) && <p></p>}
            {availableColumnsNames.includes(columnType.commissionRateAccumulationBothTotal) && <p></p>}
            <div></div>
            <div></div>
          </div>
        )}
      </section>
      {selectedPolicy && <PolicyCard closeDetails={() => setSelectedPolicy(null)} id={selectedPolicy} />}
    </>
  )
}

export default CustomerPoliciesList
