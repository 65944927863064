import { useTranslation } from 'react-i18next'
import { getIncomesTotalsTypes, incomesTotalsObject, incomesTotalsType } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { hebrew } from '../../../../i18n'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import Loader from '../../../../components/Common/Loader/Loader'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AgencyHouseAnalyticsIncomesNoData from '../AgencyHouseAnalyticsIncomesNoData/AgencyHouseAnalyticsIncomesNoData'
import { cn } from '../../../../utils/stylesUtils'
import AnalyticsChangesValue from '../AnalyticsChangesValue/AnalyticsChangesValue'
import { getKeyByValue } from '../../../../utils/objectUtils'
import { commissionKeySuffix, deltaCommissionSplitView, isDeltaAgency } from '../../../shared/reducers/sharedSelectors'
import { Fragment } from 'react'
import './AgencyHouseAnalyticsIncomesChangesTable.scss'

const AgencyHouseAnalyticsIncomesChangesTable = ({
  dataType,
  listType,
  periodViewType,
  list,
  listLoading,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly
  const notMonthlyAccView = dataType === requestDataTypes.accumulation && !monthlyView
  const deltaView =
    (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate) &&
    isDeltaAgency() &&
    !deltaCommissionSplitView()
  const deltaSplitView =
    (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate) &&
    isDeltaAgency() &&
    deltaCommissionSplitView()
  const tableHeader = (
    <header
      className={cn('agency-house-analytics-incomes-changes-table-grid', {
        extended: dataType === requestDataTypes.accumulation,
        'split-view': deltaSplitView,
        shortened: notMonthlyAccView,
      })}
    >
      <div></div>
      {!notMonthlyAccView &&
        getIncomesTotalsTypes(dataType, periodViewType).map(dt => {
          const incomesTotalKey = getKeyByValue(incomesTotalsType, dt) + (deltaView ? commissionKeySuffix() : '')
          const { name, nameEn, nameMonthlyView, nameEnMonthlyView, type } =
            incomesTotalsObject[incomesTotalsType[incomesTotalKey]]
          const text = monthlyView ? (hebrew() ? nameMonthlyView : nameEnMonthlyView) : hebrew() ? name : nameEn
          return (
            <Fragment key={type}>
              {deltaSplitView && (
                <div>
                  <div>{t('agencyPrefix') + ' ' + text}</div>
                  <div>{t('tableHeading.prevMonthDiff')}</div>
                  <div></div>
                </div>
              )}
              <div>
                <div>{(deltaSplitView ? t('agentPrefix') + ' ' : '') + text}</div>
                <div>{t('tableHeading.prevMonthDiff')}</div>
                <div></div>
              </div>
            </Fragment>
          )
        })}
      {notMonthlyAccView && (
        <div>
          <div>{t('tableHeading.accumulation')}</div>
          <div>{t('tableHeading.prevMonthDiff')}</div>
          <div></div>
        </div>
      )}
    </header>
  )

  if (!list) {
    return null
  }
  if (listLoading) {
    return <Loader />
  }
  if (list.length === 0) {
    return <AgencyHouseAnalyticsIncomesNoData />
  }
  return (
    <div className='agency-house-analytics-incomes-changes-table-container'>
      {tableHeader}
      <div className='agency-house-analytics-incomes-changes-table'>
        {list.map(itm => (
          <AgencyHouseAnalyticsIncomesChangesTableItem
            key={itm.key}
            item={itm}
            dataType={dataType}
            periodViewType={periodViewType}
            listType={listType}
            selectItem={selectItem}
            selectedItem={selectedItem}
          />
        ))}
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesChangesTable

const AgencyHouseAnalyticsIncomesChangesTableItem = ({
  item,
  dataType,
  periodViewType,
  listType,
  selectItem,
  selectedItem,
}) => {
  const deltaView =
    (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate) &&
    isDeltaAgency() &&
    !deltaCommissionSplitView()
  const deltaSplitView =
    (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate) &&
    isDeltaAgency() &&
    deltaCommissionSplitView()
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly
  const { t } = useTranslation('agencyHouseAnalytics')
  const dataKeys = getIncomesTotalsTypes(dataType, periodViewType).map(
    tt => Object.entries(incomesTotalsType).find(([key, val]) => val === tt)[0]
  )
  const itemName = hebrew() ? item.name : item.nameEn
  const notMonthlyAccView = dataType === requestDataTypes.accumulation && !monthlyView

  return (
    <div
      className={cn(
        'agency-house-analytics-incomes-changes-table-item agency-house-analytics-incomes-changes-table-grid',
        { shortened: notMonthlyAccView, 'split-view': deltaSplitView }
      )}
    >
      <div>{itemName}</div>
      {!notMonthlyAccView &&
        dataKeys.map(k => {
          const keySuffix = deltaView ? commissionKeySuffix() : ''
          const key = k + keySuffix
          const diffKey = k + 'Diff' + keySuffix
          const keyAgency = k + 'Agency'
          const keyDiffAgency = k + 'DiffAgency'
          return (
            <Fragment key={key}>
              {deltaSplitView && (
                <div>
                  <div>
                    <span>
                      {dataType === requestDataTypes.commissionRate
                        ? formatAsPercent(item[keyAgency], {
                            ...(k ===
                              getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) && {
                              maximumFractionDigits: 4,
                            }),
                          })
                        : formatAsCurrency(item[keyAgency])}
                    </span>
                    {dataType === requestDataTypes.commission &&
                      k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionMonthly) && (
                        <span className='commission-vat'>
                          ({t('tableHeading.inclVat')}:{' '}
                          {formatAsCurrency(item[getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)])})
                        </span>
                      )}
                  </div>
                  <div>
                    <AnalyticsChangesValue
                      value={item[keyDiffAgency]}
                      currency={dataType !== requestDataTypes.commissionRate}
                      percent={dataType === requestDataTypes.commissionRate}
                      {...(k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) && {
                        maximumFractionDigits: 4,
                      })}
                    />
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        selectItem({
                          key: item.key,
                          type: listType,
                          diffKey: k,
                          mainKeySuffix: 'Agency',
                          ...item,
                        })
                      }
                    >
                      <img src='./assets/export/Export.png' alt='dtls' />
                    </button>
                  </div>
                </div>
              )}
              <div>
                <div>
                  <span>
                    {dataType === requestDataTypes.commissionRate
                      ? formatAsPercent(item[key], {
                          ...(k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) && {
                            maximumFractionDigits: 4,
                          }),
                        })
                      : formatAsCurrency(item[key])}
                  </span>
                  {dataType === requestDataTypes.commission &&
                    k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionMonthly) && (
                      <span className='commission-vat'>
                        ({t('tableHeading.inclVat')}:{' '}
                        {formatAsCurrency(item[getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)])})
                      </span>
                    )}
                </div>
                <div>
                  <AnalyticsChangesValue
                    value={item[diffKey]}
                    currency={dataType !== requestDataTypes.commissionRate}
                    percent={dataType === requestDataTypes.commissionRate}
                    {...(k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) && {
                      maximumFractionDigits: 4,
                    })}
                  />
                </div>
                <div>
                  <button
                    onClick={() =>
                      selectItem({
                        key: item.key,
                        type: listType,
                        diffKey: k,
                        mainKeySuffix: keySuffix,
                        ...item,
                      })
                    }
                  >
                    <img src='./assets/export/Export.png' alt='dtls' />
                  </button>
                </div>
              </div>
            </Fragment>
          )
        })}
      {notMonthlyAccView && (
        <div>
          <div>
            <span>{formatAsCurrency(item.accumulation)}</span>
          </div>
          <div>
            <AnalyticsChangesValue value={item.accumulationDiff} currency percent={false} maximumFractionDigits={4} />
          </div>
          <div>
            <button
              onClick={() =>
                selectItem({
                  key: 'accumulation',
                  type: listType,
                  diffKey: 'accumulation',
                  mainKeySuffix: '',
                  ...item,
                })
              }
            >
              <img src='./assets/export/Export.png' alt='dtls' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
