import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { hebrew } from '../../../../i18n'
import { CUSTOMER_UNCHECK_ALL } from '../../../../types/actionTypes'
import { customersListType } from '../../../../types/tablesTypes'
import { policyRating } from '../../../../types/policyRatingTypes'
import { checkCustomer } from '../../../policy/actions/policyActions'
import {
  customersCollapseGroup,
  customersSetPage,
  customersSetPageSize,
  customersSetSort,
} from '../../actions/customersActions'
import PoliciesActions from '../../../policy/components/PoliciesActions/PoliciesActions'
import CustomersListItem from './CustomersListItem'
import NoData from '../../../../components/Common/NoData/NoData'
import Loader from '../../../../components/Common/Loader/Loader'
import PaginateIrreg from '../../../../components/Common/PaginateIrreg/PaginateIrreg'
import useDebounce from '../../../../hooks/useDebounce'
import CustomersAppliedFiltersBanner from '../CustomersAppliedFiltersBanner/CustomersAppliedFiltersBanner'
import { cn } from '../../../../utils/stylesUtils'
import { deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import { formatAsCurrency } from '../../../../utils/formatAs'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow, getColumnName } from '../../../../services/columnsConfig/columnsConfigHelpers'
import SortableColumn from '../../../../components/SortableColumn/SortableColumn'
import ColumnsPicker from '../../../../components/ColumnsPicker/ColumnsPicker'
import './CustomersListContainer.scss'

const CustomersListContainer = ({ currentListType }) => {
  const { t } = useTranslation('customers')
  const { allCustomers, page, sort, collapsedGroups, groupFilter, pageSize } = useSelector(state => state).customers
  const [itemsPerPage, setItemsPerPage] = useState(pageSize)
  const debouncedPageSize = useDebounce(itemsPerPage, 500)
  const groupsToHide = Object.entries(collapsedGroups)
    .filter(([key, v]) => !v.show)
    .map(([key]) => key)
  const totalItems = allCustomers?.groups
    ?.filter(gr => !groupsToHide.includes(gr.groupName))
    .map(grp => grp.itemsInGroup)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const { customersLoading } = useSelector(state => state.customers)
  const checkedCustomers = useSelector(state => state.policy).checkedCustomers

  const dispatch = useDispatch()

  const collapseGroup = key => {
    dispatch(customersCollapseGroup(key))
  }

  const setPageSizeHandler = pageSize => {
    setItemsPerPage(pageSize)
  }

  const setSortHandler = sortBy => {
    dispatch(customersSetSort(sortBy))
  }

  const forwardHandler = () => {
    if (page <= allCustomers.totalPages) {
      dispatch(customersSetPage(page + 1))
    }
  }
  const backwardHandler = () => {
    if (page > 1) {
      dispatch(customersSetPage(page - 1))
    }
  }

  const columnConfigPage =
    currentListType === customersListType.distribution
      ? columnsConfigPage.customersDistribution
      : columnsConfigPage.customersAll

  const availableColumns = getAvailableColumnsToShow(columnConfigPage)
  const availableColumnsNames = availableColumns.map(c => c.columnName)

  const columnsGridStyles = {
    display: 'grid',
    gridTemplateColumns: `48px repeat(${availableColumns.length === 0 ? 1 : availableColumns.length}, 1fr) ${
      availableColumns.length > 0 ? '60px' : ''
    }`,
  }

  useEffect(() => {
    if (debouncedPageSize !== 0) dispatch(customersSetPageSize(Number(debouncedPageSize)))
  }, [debouncedPageSize, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page, pageSize, currentListType])

  return (
    <>
      <div className='customers-list-container'>
        <CustomersAppliedFiltersBanner />
        {customersLoading ? (
          <Loader />
        ) : allCustomers?.groups?.length > 0 && allCustomers?.groups?.some(g => g?.itemsInGroup !== 0) ? (
          <>
            <div className={cn('customers-list-heading')} style={columnsGridStyles}>
              <div></div>
              {availableColumns.map(c =>
                c.columnName === columnType.status ? (
                  <div style={{ justifySelf: 'center' }} key={c.id}>
                    {t('listHeading.status')}
                  </div>
                ) : (
                  <SortableColumn
                    key={c.id}
                    title={getColumnName(c, t, 'listHeading')}
                    columnSortBy={c.columnName}
                    columnSortOrder={sort.order}
                    currentSortBy={sort.by}
                    setSort={setSortHandler}
                    textSort={columnConfig[c.columnName].textSort}
                  />
                )
              )}
              <div>
                <ColumnsPicker listKey={columnConfigPage} t={t} translationKey={'listHeading'} />
              </div>
            </div>
            {availableColumns.length > 0 && (
              <div
                className={cn('customers-list-item-totals overall', {
                  'split-commission': deltaCommissionSplitView(),
                })}
                style={columnsGridStyles}
              >
                <div>{t('listHeading.total')}</div>
                <div></div>
                {availableColumnsNames.includes(columnType.customerName) && <div></div>}
                {availableColumnsNames.includes(columnType.customerIdNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionAgency) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(allCustomers.totalCommissionAgency, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commission) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(allCustomers.totalCommission, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionBothTotal) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(allCustomers.totalcommissionBothTotal, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premium) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(allCustomers.totalPremium, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.accumulation) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(allCustomers.totalAccumulation, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionRatePremiumAgency) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulationAgency) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRatePremium) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulation) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRatePremiumBothTotal) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulationBothTotal) && <div></div>}
                {availableColumnsNames.includes(columnType.status) && <div></div>}
                <div></div>
              </div>
            )}
            <div className='customers-list'>
              {allCustomers.groups.map((grp, currGrpIndex, grps) => {
                return (
                  <div key={grp.groupName + currGrpIndex.toString()}>
                    {!(currentListType === customersListType.total || currentListType === customersListType.inactive) &&
                      grp.showGroup && (
                        <>
                          <div className='sublist-heading'>
                            <img
                              src={
                                !groupsToHide.includes(grp.groupName)
                                  ? './assets/minus-icon/Close.png'
                                  : './assets/plus-icon/Add.png'
                              }
                              alt='add'
                              className='img-icon'
                              onClick={() => collapseGroup(grp.groupName)}
                            />
                            <div className='sublist-heading-title' onClick={() => collapseGroup(grp.groupName)}>
                              <span className='title-content'>
                                {hebrew()
                                  ? currentListType.includes('rating')
                                    ? policyRating.find(pr => pr.key === +grp.groupName)?.name
                                    : groupFilter.find(gr => gr.id === grp.groupName - 1)?.name
                                  : currentListType.includes('rating')
                                  ? policyRating.find(pr => pr.key === +grp.groupName)?.nameEn
                                  : groupFilter.find(gr => gr.id === grp.groupName - 1)?.nameEn}
                              </span>
                              <span className='items-count'>({grp.itemsInGroup})</span>
                            </div>
                            <hr className=' sublist-heading-line' />
                          </div>
                          {availableColumns.length > 0 && (
                            <div
                              className={cn('customers-list-item-totals', {
                                'split-commission': deltaCommissionSplitView(),
                              })}
                              style={columnsGridStyles}
                            >
                              <div>{t('listHeading.total')}</div>
                              <div></div>
                              {availableColumnsNames.includes(columnType.customerName) && <div></div>}
                              {availableColumnsNames.includes(columnType.customerIdNumber) && <div></div>}
                              {availableColumnsNames.includes(columnType.commissionAgency) && (
                                <div className='financial-value-cell'>
                                  {formatAsCurrency(grp.groupCommissionAgency, { dashesForZero: false })}
                                </div>
                              )}
                              {availableColumnsNames.includes(columnType.commission) && (
                                <div className='financial-value-cell'>
                                  {formatAsCurrency(grp.groupCommission, { dashesForZero: false })}
                                </div>
                              )}
                              {availableColumnsNames.includes(columnType.commissionBothTotal) && (
                                <div className='financial-value-cell'>
                                  {formatAsCurrency(grp.commissionBothTotal, { dashesForZero: false })}
                                </div>
                              )}
                              {availableColumnsNames.includes(columnType.premium) && (
                                <div className='financial-value-cell'>
                                  {formatAsCurrency(grp.groupPremium, { dashesForZero: false })}
                                </div>
                              )}
                              {availableColumnsNames.includes(columnType.accumulation) && (
                                <div className='financial-value-cell'>
                                  {formatAsCurrency(grp.groupAccumulation, { dashesForZero: false })}
                                </div>
                              )}
                              {availableColumnsNames.includes(columnType.commissionRatePremiumAgency) && <div></div>}
                              {availableColumnsNames.includes(columnType.commissionRateAccumulationAgency) && (
                                <div></div>
                              )}
                              {availableColumnsNames.includes(columnType.commissionRatePremium) && <div></div>}
                              {availableColumnsNames.includes(columnType.commissionRateAccumulation) && <div></div>}
                              {availableColumnsNames.includes(columnType.commissionRatePremiumBothTotal) && <div></div>}
                              {availableColumnsNames.includes(columnType.commissionRateAccumulationBothTotal) && (
                                <div></div>
                              )}
                              {availableColumnsNames.includes(columnType.status) && <div></div>}
                              <div></div>
                            </div>
                          )}
                        </>
                      )}
                    {grp.showGroup &&
                    !groupsToHide.includes(grp.groupName) &&
                    (grp.groupItems.length === 0 || availableColumns.length === 0) ? (
                      <NoData text={grp.groupItems.length === 0 ? 'No Data Found' : t('noColumnsSelected')} />
                    ) : (
                      availableColumns.length > 0 &&
                      grp.groupItems.map(grpItem => (
                        <CustomersListItem
                          item={grpItem}
                          key={grpItem.id}
                          checkCustomer={() => dispatch(checkCustomer(grpItem))}
                          checked={checkedCustomers.map(c => c.id).includes(grpItem.id)}
                          styles={columnsGridStyles}
                          availableColumnsNames={availableColumnsNames}
                          listType={currentListType}
                        />
                      ))
                    )}
                  </div>
                )
              })}
              {checkedCustomers.length > 0 && (
                <div className='customers-actions-panel-wrapper'>
                  <PoliciesActions
                    customers={checkedCustomers}
                    closeModal={() => dispatch({ type: CUSTOMER_UNCHECK_ALL })}
                  />
                </div>
              )}
            </div>

            <div className='customers-paginate-wrapper'>
              <PaginateIrreg
                page={page - 1}
                pages={allCustomers.totalPages ?? 0}
                pageSize={itemsPerPage}
                totalItems={totalItems}
                forwardHandler={forwardHandler}
                backwardHandler={backwardHandler}
                setPageSize={setPageSizeHandler}
              />
            </div>
          </>
        ) : (
          <NoData text={t('noData')} />
        )}
      </div>
    </>
  )
}

export default CustomersListContainer
