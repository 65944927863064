import { hebrew } from '../../i18n'
import { formatAsCurrency, formatAsPercent, formatAsShortDate } from '../../utils/formatAs'
import { getYearAndMonth } from '../getMonthName'

export const columnType = {
  accumulation: 'Accumulation',
  averageCommissionRate: 'AverageCommissionRate',
  category: 'Category',
  commission: 'Commission',
  commissionAgency: 'CommissionAgency',
  commissionBothTotal: 'CommissionBothTotal',
  commissionMonthly: 'CommissionMonthly',
  commissionMonthlyAgency: 'CommissionMonthlyAgency',
  commissionMonthlyBothTotal: 'CommissionMonthlyBothTotal',
  commissionRate: 'CommissionRate',
  commissionRateAccumulation: 'CommissionRateAccumulation',
  commissionRateAccumulationAgency: 'CommissionRateAccumulationAgency',
  commissionRateAccumulationBothTotal: 'CommissionRateAccumulationBothTotal',
  commissionRateAgency: 'CommissionRateAgency',
  commissionRateBothTotal: 'CommissionRateBothTotal',
  commissionRatePremium: 'CommissionRatePremium',
  commissionRatePremiumAgency: 'CommissionRatePremiumAgency',
  commissionRatePremiumBothTotal: 'CommissionRatePremiumBothTotal',
  company: 'Company',
  customerIdNumber: 'CustomerIdNumber',
  customerName: 'CustomerName',
  endDate: 'EndDate',
  expectedCommissionRate: 'ExpectedCommissionRate',
  groupName: 'GroupName',
  mainReport: 'MainReport',
  month: 'Month',
  monthlyIrregularitiesCount: 'MonthlyIrregularitiesCount',
  monthOfPayment: 'MonthOfPayment',
  paymentIdentifierNumber: 'PaymentIdentifierNumber',
  paymentName: 'PaymentName',
  policyAge: 'PolicyAge',
  policyNumber: 'PolicyNumber',
  policyTypeName: 'PolicyTypeName',
  premium: 'Premium',
  premiumMonthly: 'PremiumMonthly',
  premiumBrutto: 'PremiumBrutto',
  premiumBruttoMonthly: 'PremiumBruttoMonthly',
  fees: 'Fees',
  pullingDate: 'PullingDate',
  reportName: 'ReportName',
  sideReport: 'SideReport',
  startDate: 'StartDate',
  status: 'Status',
  employerName: 'EmployerName',
  employerNumber: 'EmployerNumber',
}

export const columnsConfigPage = {
  policiesLife: 'policies.Life',
  policiesElementary: 'policies.Elementary',
  specialPayment: 'specialPayment',
  policiesNewElementary: 'policies.New.Elementary',
  policiesNewLife: 'policies.New.Life',
  policiesLostElementary: 'policies.Lost.Elementary',
  policiesLostLife: 'policies.Lost.Life',
  irregularPolicy: 'irregularPolicy',
  coverage: 'coverage',
  customersAll: 'customers.All',
  customersDistribution: 'customers.Distribution',
  customersGroups: 'customers.Groups',
  customerElementary: 'customer.Elementary',
  customerLife: 'customer.Life',
  customerAbroad: 'customer.Abroad',
}

export const pagesColumnsMap = {
  [columnsConfigPage.policiesLife]: [
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.startDate },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
  ],
  [columnsConfigPage.policiesElementary]: [
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.startDate },
    { columnName: columnType.endDate },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.premiumBrutto },
    { columnName: columnType.fees },
    { columnName: columnType.commissionMonthlyAgency },
    { columnName: columnType.commissionMonthly },
    { columnName: columnType.commissionMonthlyBothTotal },
    { columnName: columnType.premiumMonthly },
    { columnName: columnType.premiumBruttoMonthly },
    { columnName: columnType.commissionRateAgency },
    { columnName: columnType.commissionRate },
    { columnName: columnType.commissionRateBothTotal },
  ],
  [columnsConfigPage.specialPayment]: [
    { columnName: columnType.paymentIdentifierNumber },
    { columnName: columnType.paymentName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.company, columnContent: entity => <div>{entity.companyName ?? '--'}</div> },
    { columnName: columnType.category, columnContent: entity => <div>{entity.categoryName ?? '--'}</div> },
    { columnName: columnType.monthOfPayment },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
  ],
  [columnsConfigPage.policiesNewLife]: [
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.startDate },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
  ],
  [columnsConfigPage.policiesNewElementary]: [
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.startDate },
    { columnName: columnType.endDate },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.premiumBrutto },
    { columnName: columnType.fees },
    { columnName: columnType.commissionMonthlyAgency },
    { columnName: columnType.commissionMonthly },
    { columnName: columnType.commissionMonthlyBothTotal },
    { columnName: columnType.premiumMonthly },
    { columnName: columnType.premiumBruttoMonthly },
    { columnName: columnType.commissionRateAgency },
    { columnName: columnType.commissionRate },
    { columnName: columnType.commissionRateBothTotal },
  ],
  [columnsConfigPage.policiesLostLife]: [
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.startDate },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
  ],
  [columnsConfigPage.policiesLostElementary]: [
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.startDate },
    { columnName: columnType.endDate },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.premiumBrutto },
    { columnName: columnType.fees },
    { columnName: columnType.commissionMonthlyAgency },
    { columnName: columnType.commissionMonthly },
    { columnName: columnType.commissionMonthlyBothTotal },
    { columnName: columnType.premiumMonthly },
    { columnName: columnType.premiumBruttoMonthly },
    { columnName: columnType.commissionRateAgency },
    { columnName: columnType.commissionRate },
    { columnName: columnType.commissionRateBothTotal },
  ],
  [columnsConfigPage.irregularPolicy]: [
    { columnName: columnType.month },
    { columnName: columnType.monthlyIrregularitiesCount },
    { columnName: columnType.policyNumber, cssGridColumnWidth: 'minmax(3ch, 1fr)' },
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.policyAge },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.premiumBrutto },
    { columnName: columnType.fees },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
  ],
  [columnsConfigPage.coverage]: [
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.policyTypeName },
    { columnName: columnType.reportName },
    { columnName: columnType.averageCommissionRate },
    { columnName: columnType.expectedCommissionRate },
  ],
  [columnsConfigPage.customersAll]: [
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
    { columnName: columnType.status },
  ],
  [columnsConfigPage.customersDistribution]: [
    { columnName: columnType.customerName },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
    { columnName: columnType.status },
  ],
  [columnsConfigPage.customersGroups]: [
    { columnName: columnType.groupName },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premium },
    { columnName: columnType.accumulation },
  ],
  [columnsConfigPage.customerElementary]: [
    { columnName: columnType.policyNumber },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.customerName },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.premium },
    { columnName: columnType.premiumBrutto },
    { columnName: columnType.fees },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.premiumMonthly },
    { columnName: columnType.premiumBruttoMonthly },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
  ],
  [columnsConfigPage.customerLife]: [
    { columnName: columnType.policyNumber },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.customerName },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.premium },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.accumulation },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
    { columnName: columnType.commissionRateAccumulationAgency },
    { columnName: columnType.commissionRateAccumulation },
    { columnName: columnType.commissionRateAccumulationBothTotal },
  ],
  [columnsConfigPage.customerAbroad]: [
    { columnName: columnType.policyNumber },
    { columnName: columnType.customerIdNumber },
    { columnName: columnType.customerName },
    { columnName: columnType.employerName },
    { columnName: columnType.employerNumber },
    { columnName: columnType.company },
    { columnName: columnType.category },
    { columnName: columnType.premium },
    { columnName: columnType.commissionAgency },
    { columnName: columnType.commission },
    { columnName: columnType.commissionBothTotal },
    { columnName: columnType.commissionRatePremiumAgency },
    { columnName: columnType.commissionRatePremium },
    { columnName: columnType.commissionRatePremiumBothTotal },
  ],
}

export const columnConfig = {
  [columnType.accumulation]: {
    columnName: 'Accumulation',
    columnContent: entity => <div>{formatAsCurrency(entity.accumulation, { dashesForZero: false })}</div>,
  },
  [columnType.averageCommissionRate]: { columnName: 'AverageCommissionRate' },
  [columnType.category]: {
    columnName: 'Category',
    textSort: true,
    columnContent: entity => {
      let category
      if (entity.category) {
        category = entity.category[`name${hebrew() ? '' : 'En'}`]
      } else if (entity.categoryName || entity.categoryNameEn) {
        category = entity[`categoryName${hebrew() ? '' : 'En'}`]
      } else {
        category = '--'
      }
      return <div>{category}</div>
    },
  },
  [columnType.commission]: {
    columnName: 'Commission',
    columnContent: entity => (
      <div className='financial-value-cell'>{formatAsCurrency(entity.commission, { dashesForZero: false })}</div>
    ),
  },
  [columnType.commissionAgency]: {
    columnName: 'CommissionAgency',
    columnContent: entity => (
      <div className='financial-value-cell'>{formatAsCurrency(entity.commissionAgency, { dashesForZero: false })}</div>
    ),
  },
  [columnType.commissionBothTotal]: {
    columnName: 'CommissionBothTotal',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsCurrency(entity.commissionBothTotal, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionMonthly]: {
    columnName: 'CommissionMonthly',
    columnContent: entity => (
      <div className='financial-value-cell'>{formatAsCurrency(entity.commissionMonthly, { dashesForZero: false })}</div>
    ),
  },
  [columnType.commissionMonthlyAgency]: {
    columnName: 'CommissionMonthlyAgency',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsCurrency(entity.commissionMonthlyAgency, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionMonthlyBothTotal]: {
    columnName: 'CommissionMonthlyBothTotal',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsCurrency(entity.commissionMonthlyBothTotal, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRate]: {
    columnName: 'CommissionRate',
    columnContent: entity => (
      <div className='financial-value-cell'>{formatAsPercent(entity.commissionRate, { dashesForZero: false })}</div>
    ),
  },
  [columnType.commissionRateAccumulation]: {
    columnName: 'CommissionRateAccumulation',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRateAccumulation, { maximumFractionDigits: 4, dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRateAccumulationAgency]: {
    columnName: 'CommissionRateAccumulationAgency',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRateAccumulationAgency, { maximumFractionDigits: 4, dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRateAccumulationBothTotal]: {
    columnName: 'CommissionRateAccumulationBothTotal',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRateAccumulationBothTotal, {
          maximumFractionDigits: 4,
          dashesForZero: false,
        })}
      </div>
    ),
  },
  [columnType.commissionRateAgency]: {
    columnName: 'CommissionRateAgency',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRateAgency, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRateBothTotal]: {
    columnName: 'CommissionRateBothTotal',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRateBothTotal, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRatePremium]: {
    columnName: 'CommissionRatePremium',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRatePremium, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRatePremiumAgency]: {
    columnName: 'CommissionRatePremiumAgency',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRatePremiumAgency, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.commissionRatePremiumBothTotal]: {
    columnName: 'CommissionRatePremiumBothTotal',
    columnContent: entity => (
      <div className='financial-value-cell'>
        {formatAsPercent(entity.commissionRatePremiumBothTotal, { dashesForZero: false })}
      </div>
    ),
  },
  [columnType.company]: {
    columnName: 'Company',
    textSort: true,
    columnContent: entity => {
      let company
      if (entity.company) {
        company = entity.company
      } else if (entity.companyName || entity.companyNameEn) {
        company = entity[`companyName${hebrew() ? '' : 'En'}`]
      } else {
        company = '--'
      }
      return <div>{company}</div>
    },
  },
  [columnType.customerIdNumber]: {
    columnName: 'CustomerIdNumber',
    textSort: true,
    columnContent: entity => <div>{entity.customerIdNumber ?? '--'}</div>,
  },
  [columnType.customerName]: {
    columnName: 'CustomerName',
    textSort: true,
    columnContent: entity => <div>{entity.customerName ?? '--'}</div>,
  },
  [columnType.endDate]: {
    columnName: 'EndDate',
    columnContent: entity => <div>{formatAsShortDate(entity.endDate) ?? '--'}</div>,
  },
  [columnType.expectedCommissionRate]: { columnName: 'ExpectedCommissionRate' },
  [columnType.groupName]: { columnName: 'GroupName' },
  [columnType.mainReport]: { columnName: 'MainReport' },
  [columnType.month]: {
    columnName: 'Month',
    columnContent: entity => <div>{getYearAndMonth(entity.month, { language: hebrew() ? 'he' : 'en' })}</div>,
  },
  [columnType.monthlyIrregularitiesCount]: {
    columnName: 'MonthlyIrregularitiesCount',
    columnContent: entity => <div>{entity.monthlyIrregularitiesCount ?? '--'}</div>,
  },
  [columnType.monthOfPayment]: {
    columnName: 'MonthOfPayment',
    columnContent: entity => <div>{formatAsShortDate(entity.monthOfPayment) ?? '--'}</div>,
  },
  [columnType.paymentIdentifierNumber]: {
    columnName: 'PaymentIdentifierNumber',
    columnContent: entity => <div>{entity.paymentIdentifierNumber ?? '--'}</div>,
  },
  [columnType.paymentName]: {
    columnName: 'PaymentName',
    columnContent: entity => <div>{entity.paymentIdentifiepaymentNameNumber ?? '--'}</div>,
  },
  [columnType.policyAge]: { columnName: 'PolicyAge', columnContent: entity => <div>{entity.policyAge ?? '--'}</div> },
  [columnType.policyNumber]: {
    columnName: 'PolicyNumber',
    textSort: true,
    columnContent: entity => <div>{entity.policyNumber ?? '--'}</div>,
  },
  [columnType.policyTypeName]: { columnName: 'PolicyTypeName' },
  [columnType.premium]: {
    columnName: 'Premium',
    columnContent: entity => <div>{formatAsCurrency(entity.premium, { dashesForZero: false })}</div>,
  },
  [columnType.premiumMonthly]: {
    columnName: 'PremiumMonthly',
    columnContent: entity => <div>{formatAsCurrency(entity.premiumMonthly, { dashesForZero: false })}</div>,
  },
  [columnType.premiumBrutto]: {
    columnName: 'PremiumBrutto',
    columnContent: entity => <div>{formatAsCurrency(entity.premiumBrutto, { dashesForZero: false })}</div>,
  },
  [columnType.premiumBruttoMonthly]: {
    columnName: 'PremiumBruttoMonthly',
    columnContent: entity => <div>{formatAsCurrency(entity.premiumBruttoMonthly, { dashesForZero: false })}</div>,
  },
  [columnType.fees]: {
    columnName: 'Fees',
    columnContent: entity => <div>{formatAsCurrency(entity.fees, { dashesForZero: false })}</div>,
  },
  [columnType.pullingDate]: { columnName: 'PullingDate' },
  [columnType.reportName]: { columnName: 'ReportName' },
  [columnType.sideReport]: { columnName: 'SideReport' },
  [columnType.startDate]: {
    columnName: 'StartDate',
    columnContent: entity => <div>{formatAsShortDate(entity.startDate) ?? '--'}</div>,
  },
  [columnType.status]: { columnName: 'Status' },
  [columnType.employerName]: {
    columnName: 'EmployerName',
    textSort: true,
    columnContent: entity => <div>{entity.employerName ?? '--'}</div>,
  },
  [columnType.employerNumber]: {
    columnName: 'EmployerNumber',
    textSort: true,
    columnContent: entity => <div>{entity.employerNumber ?? '--'}</div>,
  },
}
