import { Fragment, useState } from 'react'
import { allPoliciesListType } from '../../../../types/tablesTypes'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatus } from '../../../../types/policyStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import { useTranslation } from 'react-i18next'
import PoliciesSyncIcon from '../PoliciesSyncIcon/PoliciesSyncIcon'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { Tooltip } from '@mui/material'
import './PoliciesAllListItem.scss'

const PoliciesAllListItem = ({ policy, listType, classes, selectPolicy, selected, styles }) => {
  const { t } = useTranslation('policies')
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  let columnsPageKey

  switch (listType) {
    case allPoliciesListType.life:
    default:
      columnsPageKey = columnsConfigPage.policiesLife
      break
    case allPoliciesListType.elementary:
      columnsPageKey = columnsConfigPage.policiesElementary
      break
    case allPoliciesListType.specialPayments:
      columnsPageKey = columnsConfigPage.specialPayment
  }

  const availableColumns = getAvailableColumnsToShow(columnsPageKey)

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-all-list-item', policyStatus[policy.status]?.type, {
          outdated: policy.isOutdated,
          'has-policy-number': availableColumns.map(c => c.columnName).includes(columnType.policyNumber),
        })}
        selectable={listType !== allPoliciesListType.specialPayments}
        onSelect={() => selectPolicy(policy.id)}
        selected={selected}
        {...(listType !== allPoliciesListType.specialPayments && { onClick: () => setShowPolicyCard(true) })}
        styles={styles}
      >
        {availableColumns.map(c => (
          <Fragment key={c.columnName}>
            {c.columnName === columnType.policyNumber ? (
              <Tooltip
                title={policy.policyNumber}
                classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                arrow
                placement='top'
              >
                {columnConfig[c.columnName].columnContent(policy)}
              </Tooltip>
            ) : (
              c.columnContent(policy)
            )}
          </Fragment>
        ))}
        {listType !== allPoliciesListType.specialPayments && (
          <>
            <div className='policy-badges'>
              <PolicyStatusChip status={policy.status} />
              {!policy.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
            </div>
            <div className='policies-all-list-item-icons'>
              {<PoliciesSyncIcon tooltip={t('list.tooltips.outdatedPolicy')} enabled={policy.isOutdated} />}
            </div>
          </>
        )}
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={policy.id} />}
    </>
  )
}

export default PoliciesAllListItem
