import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import NoData from '../../../../components/Common/NoData/NoData'
import Loader from '../../../../components/Common/Loader/Loader'
import { customersSetPage, customersSetPageSize, getCustomersGroups } from '../../actions/customersActions'
import CustomersGroupsList from './CustomersGroupsList'
import { CUSTOMERS_SET_GROUPS_SORT_BY } from '../../../../types/actionTypes'
import { sortBy, sortOrder } from '../../../../types/sortByTypes'
import PaginateIrreg from '../../../../components/Common/PaginateIrreg/PaginateIrreg'
import useDebounce from '../../../../hooks/useDebounce'
import { commissionHeaderKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import { cn } from '../../../../utils/stylesUtils'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import { columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import ColumnsPicker from '../../../../components/ColumnsPicker/ColumnsPicker'
import './CustomersGroupsListsContainer.scss'

const CustomersGroupsListsContainer = () => {
  const { t } = useTranslation('customers')
  const {
    companiesFilter,
    categoriesFilter,
    customersGroups,
    groupsTotalPages,
    customersGroupsLoading,
    groupsSort,
    customersCollapsedGroups,
    page,
    pageSize,
    groupTypesFilter,
    searchTerm,
  } = useSelector(({ customers }) => customers)
  const { selectedGroupsIds, familyGroupsType } = useSelector(({ shared }) => shared)

  const [selectedGroups, setSelectedGroups] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(pageSize)
  const debouncedPageSize = useDebounce(itemsPerPage, 500)

  const totalItems = customersGroups
    .filter(gr => !(customersCollapsedGroups.indexOf(gr.groupName) > -1))
    .map(gr => gr.itemsInGroup)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const dispatch = useDispatch()

  const selectGroupsHandler = ids => {
    const allAlreadySelected = ids.every(id => selectedGroups.includes(id))
    if (allAlreadySelected) {
      setSelectedGroups(selectedGroups.filter(id => !ids.includes(id)))
    } else {
      const notYetSelected = ids.filter(id => !selectedGroups.includes(id))
      setSelectedGroups([...selectedGroups, ...notYetSelected])
    }
  }

  const setSortHandler = sortBy => {
    dispatch({ type: CUSTOMERS_SET_GROUPS_SORT_BY, payload: sortBy })
  }

  const forwardHandler = () => {
    if (page <= groupsTotalPages) {
      dispatch(customersSetPage(page + 1))
    }
  }
  const backwardHandler = () => {
    if (page > 1) {
      dispatch(customersSetPage(page - 1))
    }
  }

  const setPageSizeHandler = pageSize => {
    setItemsPerPage(pageSize)
  }
  const columnConfigPage = columnsConfigPage.customersGroups
  const availableColumnsNames = getAvailableColumnsToShow(columnConfigPage).map(c => c.columnName)

  const columnsGridStyles = {
    display: 'grid',
    gridTemplateColumns: `48px repeat(${availableColumnsNames.length === 0 ? 1 : availableColumnsNames.length}, 1fr) ${
      availableColumnsNames.length > 0 ? '58px' : ''
    }`,
  }

  useEffect(() => {
    groupTypesFilter.length > 0 && dispatch(getCustomersGroups())
  }, [
    companiesFilter,
    categoriesFilter,
    groupTypesFilter,
    groupsSort,
    customersCollapsedGroups,
    page,
    pageSize,
    searchTerm,
    familyGroupsType,
    dispatch,
  ])

  useEffect(() => {
    if (debouncedPageSize !== 0) dispatch(customersSetPageSize(Number(debouncedPageSize)))
  }, [debouncedPageSize, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (page === 1) {
      dispatch(getCustomersGroups())
    } else {
      dispatch(customersSetPage(1))
    }
  }, [selectedGroupsIds, dispatch])

  if (customersGroupsLoading) {
    return (
      <div className='customers-groups-lists-container'>
        <Loader />
      </div>
    )
  }

  if (customersGroups.length === 0) {
    return (
      <div className='customers-groups-lists-container'>
        <NoData text={t('noData')} />
      </div>
    )
  }

  return (
    <div className='customers-groups-lists-container'>
      <div
        className={cn('customers-groups-lists-header customers-groups-list-grid', {
          'split-commission': deltaCommissionSplitView(),
        })}
        style={columnsGridStyles}
      >
        <div></div>
        {availableColumnsNames.includes(columnType.groupName) && (
          <div className='sortable' onClick={() => setSortHandler(sortBy.name)}>
            {t('listHeading.groupName')}
            {groupsSort.by === sortBy.name && (
              <i className={`fas fa-sort-alpha-down${groupsSort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
            )}
          </div>
        )}
        {availableColumnsNames.includes(columnType.premium) && (
          <div className='sortable' onClick={() => setSortHandler(sortBy.premium)}>
            {t('listHeading.premium')}
            {groupsSort.by === sortBy.premium && (
              <i className={`fas fa-sort-numeric-down${groupsSort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
            )}
          </div>
        )}
        {availableColumnsNames.includes(columnType.commissionAgency) && (
          <div className='sortable' onClick={() => setSortHandler(sortBy.commissionAgency)}>
            <span>{t('listHeading.commissionAgency')}</span>
            {groupsSort.by === sortBy.commissionAgency && (
              <i className={`fas fa-sort-numeric-down${groupsSort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
            )}
          </div>
        )}
        {availableColumnsNames.includes(columnType.commission) && (
          <div className='sortable' onClick={() => setSortHandler(sortBy.commission)}>
            <span>{t(`listHeading.commission${commissionHeaderKeySuffix()}`)}</span>
            {groupsSort.by === sortBy.commission && (
              <i className={`fas fa-sort-numeric-down${groupsSort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
            )}
          </div>
        )}
        {availableColumnsNames.includes(columnType.commissionBothTotal) && (
          <div className='sortable' onClick={() => setSortHandler(sortBy.commission)}>
            <span>{t(`listHeading.commission${commissionHeaderKeySuffix()}`)}</span>
            {groupsSort.by === sortBy.commissionBothTotal && (
              <i className={`fas fa-sort-numeric-down${groupsSort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
            )}
          </div>
        )}
        {availableColumnsNames.includes(columnType.accumulation) && (
          <div className='sortable' onClick={() => setSortHandler(sortBy.accumulation)}>
            {t('listHeading.accumulation')}{' '}
            {groupsSort.by === sortBy.accumulation && (
              <i className={`fas fa-sort-numeric-down${groupsSort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
            )}
          </div>
        )}
        <div>
          <ColumnsPicker listKey={columnConfigPage} t={t} translationKey={'listHeading'} />
        </div>
      </div>
      <div className='customers-groups-lists'>
        {availableColumnsNames.length === 0 && <NoData text={t('noColumnsSelected')} />}
        {availableColumnsNames.length > 0 &&
          customersGroups
            .filter(gr => gr.showGroup)
            .map(gr => (
              <CustomersGroupsList
                groups={gr}
                key={gr.groupName}
                selectGroups={selectGroupsHandler}
                selectedGroups={selectedGroups}
              />
            ))}
      </div>
      <div className='customers-paginate-wrapper'>
        <PaginateIrreg
          page={page - 1}
          pages={groupsTotalPages ?? 0}
          pageSize={itemsPerPage}
          totalItems={totalItems}
          forwardHandler={forwardHandler}
          backwardHandler={backwardHandler}
          setPageSize={setPageSizeHandler}
        />
      </div>
    </div>
  )
}

export default CustomersGroupsListsContainer
