import { Fragment, useState } from 'react'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { SyncProblemOutlined } from '@mui/icons-material'
import { entityStatusType } from '../../../../types/entityStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { hebrew } from '../../../../i18n'
import { allPoliciesListType } from '../../../../types/tablesTypes'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import { Tooltip } from '@mui/material'
import { policyStatus } from '../../../../types/policyStatusType'
import './PoliciesNewListItem.scss'

const PoliciesNewListItem = ({ policy, classes, selectPolicy, selected, listType, styles }) => {
  const [showPolicyCard, setShowPolicyCard] = useState(false)

  /* temporary disabled (task: board - 5570988580 pulse - 7596845841) */
  // const [showPayments, setShowPayments] = useState(false)
  const elementaryListType = listType === allPoliciesListType.elementary
  const keySuffix = commissionKeySuffix()
  let columnsPageKey

  switch (listType) {
    case allPoliciesListType.life:
    default:
      columnsPageKey = columnsConfigPage.policiesNewLife
      break
    case allPoliciesListType.elementary:
      columnsPageKey = columnsConfigPage.policiesNewElementary
      break
  }

  const availableColumns = getAvailableColumnsToShow(columnsPageKey)

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-new-list-item', policyStatus[policy.status]?.type, {
          outdated: policy.isOutdated,
          'has-policy-number': availableColumns.map(c => c.columnName).includes(columnType.policyNumber),
        })}
        onClick={() => setShowPolicyCard(true)}
        selectable
        onSelect={() => selectPolicy(policy.id)}
        selected={selected}
        styles={styles}
      >
        <>
          {/* temporary disabled (task: board - 5570988580 pulse - 7596845841) */}
          {/* <div>
            <button
              onClick={e => {
                e.stopPropagation()
                setShowPayments(prev => !prev)
              }}
            >
              <img src={`./assets/${showPayments ? 'minus-icon/Close' : 'plus-icon/Add'}.png`} alt='more' />
            </button>
          </div> */}
          {availableColumns.map(c => (
            <Fragment key={c.columnName}>
              {c.columnName === columnType.policyNumber ? (
                <Tooltip
                  title={policy.policyNumber}
                  classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                  arrow
                  placement='top'
                >
                  {columnConfig[c.columnName].columnContent(policy)}
                </Tooltip>
              ) : (
                columnConfig[c.columnName].columnContent(policy)
              )}
            </Fragment>
          ))}
          <div className='policy-badges'>
            <PolicyStatusChip status={policy.status} />
            {!policy.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
          </div>
          <div className='policies-new-list-item-icons'>
            {policy.isOutdated && <SyncProblemOutlined style={{ color: 'var(--orange-client)' }} />}
          </div>
        </>
        {/* temporary disabled (task: board - 5570988580 pulse - 7596845841) */}
        {/* {showPayments &&
          policy.payments.map((p, i) => (
            <div className={`policies-new-list-grid subgrid ${elementaryListType && 'policies-new-list-grid-elementary'}`} key={p.pullingDate + i}>
              <div></div>
              <div></div>
              <div>
                {getMonthName(p.pullingDate, language)} {new Date(p.pullingDate).getFullYear()}
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              {elementaryListType && <div></div>}
              <div className='financial-value-cell'>{formatAsCurrency(p.commission, { dashesForZero: false })}</div>
              <div className='financial-value-cell'>{formatAsCurrency(p.premium, { dashesForZero: false })}</div>
              {!elementaryListType && (
                <>
                <div className='financial-value-cell'>{formatAsCurrency(p.accumulation, { dashesForZero: false })}</div>
              <div className='financial-value-cell'>
                {formatAsPercent(p.commissionRatePremium, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsPercent(p.commissionRateAccumulation, { maximumFractionDigits: 4, dashesForZero: false })}
              </div>
              </>)}
              {elementaryListType && (<>
                <div className='financial-value-cell'>
                {formatAsCurrency(p.commissionMonthly, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsCurrency(p.premiumMonthly, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsPercent(p.commissionRate, { dashesForZero: false })}
              </div>
              </>)}
              
              <div className='policy-badges'></div>
              <div className='policies-new-list-item-icons'></div>
            </div>
          ))} */}
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={policy.id} />}
    </>
  )
}

export default PoliciesNewListItem
