export const sortBy = {
  name: 'Name',
  commission: 'Commission',
  commissionAgency: 'CommissionAgency',
  commissionAgents: 'CommissionAgents',
  commissionBothTotal: 'CommissionBothTotal',
  commissionRate: 'CommissionRate',
  commissionRatePremium: 'CommissionRatePremium',
  commissionRateAccumulation: 'CommissionRateAccumulation',
  commissionRateAgency: 'CommissionRateAgency',
  commissionRatePremiumAgency: 'CommissionRatePremiumAgency',
  commissionRateAccumulationAgency: 'CommissionRateAccumulationAgency',
  commissionRateBothTotal: 'CommissionRateBothTotal',
  commissionRatePremiumBothTotal: 'CommissionRatePremiumBothTotal',
  commissionRateAccumulationBothTotal: 'CommissionRateAccumulationBothTotal',
  premium: 'Premium',
  premiumBrutto: 'PremiumBrutto',
  fees: 'Fees',
  accumulation: 'Accumulation',
  idNumber: 'idNumber',
  company: 'Company',
  policyNumber: 'PolicyNumber',
  category: 'Category',
  customerName: 'CustomerName',
  customerIdNumber: 'CustomerIdNumber',
  taskType: 'TaskType',
  commissionMonthly: 'CommissionMonthly',
  commissionMonthlyAgency: 'CommissionMonthlyAgency',
  commissionMonthlyBothTotal: 'CommissionMonthlyBothTotal',
  premiumMonthly: 'PremiumMonthly',
  premiumBruttoMonthly: 'PremiumBruttoMonthly',
  paymentIdentifierNumber: 'PaymentIdentifierNumber',
  paymentNumber: 'PaymentNumber',
  paymentName: 'PaymentName',
  startDate: 'StartDate',
  endDate: 'EndDate',
  policyAge: 'PolicyAge',
  month: 'Month',
  monthOfPayment: 'MonthOfPayment',
  monthlyIrregularitiesCount: 'MonthlyIrregularitiesCount',
}

export const sortOrder = {
  asc: 'ascending',
  desc: 'descending',
}
