import classNames from 'classnames'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InputText from '../../components/Common/InputText/InputText'
import Logo from '../../components/Logo/Logo'
import { urlMaster } from '../../environment/urls'
import LandingFooter from '../../features/landing/components/LandingFooter/LandingFooter'
import $api from '../../http'
import BackButton from '../../components/BackButton/BackButton'
import InputSelect from '../../components/Common/InputSelect/InputSelect'
import { customerData } from '../../types/customerData'
import EarlyAccessSuccessModal from '../../components/EarlyAccessSuccessModal/EarlyAccessSuccessModal'
import './EarlyAccessPage.scss'

const EarlyAccessPage = () => {
  const {
    info: { fullName, mobilePhone: mobile },
  } = useSelector(state => state.landing)
  const { t } = useTranslation('early')
  const history = useHistory()
  const [data, setData] = useState({
    fullName,
    agencyName: '',
    email: '',
    mobile,
    howFind: '',
  })
  const dataAmount = customerData
  const [loading, setLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [errorMobile, setErrorMobile] = useState(null)
  const [errorServer, setErrorServer] = useState(null)
  const mobileInputRef = useRef(null)

  const changeHanlder = (value, key) => {
    if (key === 'mobile') {
      errorMobile && setErrorMobile(null)
      value = value.replace(/\D/g, '')
    }
    setData(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const submitHandler = async e => {
    e.preventDefault()
    setLoading(true)
    const { mobile } = data
    const mobileFormatted = `+972${mobile.charAt(0) === '0' ? mobile.slice(1) : mobile}`
    const requestDto = { ...data, mobile: mobileFormatted }
    try {
      setErrorMobile(null)
      setErrorServer(null)
      const { data } = await $api.post(`${urlMaster()}account/add-create-agency-request`, requestDto)
      if (data?.Data?.succeed) history.push('/')
      else if (data?.HasError) {
        setErrorMobile(data.ErrorMessage)
        mobileInputRef.current.focus()
      }
    } catch (error) {
      setErrorServer('Server Error')
    }
    setLoading(false)
    setShowSuccessModal(true)
  }

  useEffect(() => {
    if (errorServer) {
      const timer = setTimeout(() => {
        if (errorServer) setErrorServer(null)
      }, 5000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [errorServer])

  return (
    <div className='early-main-container'>
      <header>
        <div className='desktop-logo'>
          <Logo />
        </div>

        <div className='mobile-back-arrow'>
          <BackButton />
        </div>
      </header>
      <div
        className='early-content-wrapper'
        style={{
          backgroundImage: `url('assets/_early/BG.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div
          className='early-content-container'
          style={{
            backgroundImage: `url('assets/_early/early-mobile-background.png')`,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='early-heading-container'>
            <h2>{t('heading')}</h2>
            <h4>{t('subHeading')}</h4>
            <p>{t('text')}</p>
          </div>
          <div className='early-form-container'>
            <form action='' onSubmit={submitHandler}>
              <h4>{t('formHeading')}</h4>
              <div className='early-form-inputs-container'>
                <div>
                  {errorServer && <h6 className='has-server-error'>{errorServer}</h6>}
                  <div className='early-form-inputs-row'>
                    <InputText
                      label={t('formLabels.fullName')}
                      required
                      value={data.fullName}
                      onChange={val => changeHanlder(val, 'fullName')}
                      inputName={'fullName'}
                      disabled={loading}
                    />
                    <InputText
                      label={t('formLabels.agencyName')}
                      value={data.agencyName}
                      onChange={val => changeHanlder(val, 'agencyName')}
                      inputName={'agencyName'}
                      disabled={loading}
                    />
                  </div>
                  <div className={classNames('early-form-inputs-row', { 'has-mobile-error': errorMobile })}>
                    <InputText
                      inputType='email'
                      label={t('formLabels.email')}
                      required
                      value={data.email}
                      onChange={val => changeHanlder(val, 'email')}
                      inputName={'email'}
                      disabled={loading}
                    />
                    <InputText
                      label={errorMobile ? errorMobile : t('formLabels.mobile')}
                      required={!!!errorMobile}
                      value={data.mobile}
                      onChange={val => changeHanlder(val, 'mobile')}
                      inputName={'mobile'}
                      id={'mobile'}
                      disabled={loading}
                      minLength={9}
                      maxLength={10}
                      inputType={'tel'}
                      inputRef={mobileInputRef}
                    />
                  </div>
                  <InputSelect
                    selectedValue={data.howFind}
                    onChange={val => changeHanlder(val, 'howFind')}
                    values={dataAmount}
                    label={'מספר לקוחות'}
                  />
                </div>
              </div>
              <div>
                <button type='submit' disabled={loading}>
                  {t('sendBtn')}
                </button>
              </div>
            </form>
          </div>
          {/* <div className='early-remark-container'>
            <h3>{t('remarkHeading')}</h3>
            <p>{t('remarkText1')}</p>
            <p>{t('remarkText2')}</p>
          </div> */}
        </div>
      </div>
      <LandingFooter />
      {showSuccessModal && <EarlyAccessSuccessModal />}
    </div>
  )
}

export default EarlyAccessPage
