import { Fragment, useState } from 'react'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { useTranslation } from 'react-i18next'
import PoliciesSyncIcon from '../PoliciesSyncIcon/PoliciesSyncIcon'
import PolicyListItemMenu from '../PolicyListItemMenu/PolicyListItemMenu'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { allPoliciesListType } from '../../../../types/tablesTypes'
import { policyStatus } from '../../../../types/policyStatusType'
import { Tooltip } from '@mui/material'
import './PoliciesLostListItem.scss'

const PoliciesLostListItem = ({ policy, classes, selectPolicy, selected, onPolicyAddCustomer, styles, listType }) => {
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  const { t } = useTranslation('policy')
  let columnsPageKey

  switch (listType) {
    case allPoliciesListType.life:
    default:
      columnsPageKey = columnsConfigPage.policiesLostLife
      break
    case allPoliciesListType.elementary:
      columnsPageKey = columnsConfigPage.policiesLostElementary
      break
  }
  const availableColumns = getAvailableColumnsToShow(columnsPageKey)

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-lost-list-item', policyStatus[policy.status]?.type, {
          outdated: policy.isOutdated,
          'has-policy-number': availableColumns.map(c => c.columnName).includes(columnType.policyNumber),
        })}
        onClick={() => setShowPolicyCard(true)}
        selectable
        onSelect={() => selectPolicy(policy.id)}
        selected={selected}
        styles={styles}
      >
        {availableColumns.map(c => (
          <Fragment key={c.columnName}>
            {c.columnName === columnType.policyNumber ? (
              <Tooltip
                title={policy.policyNumber}
                classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                arrow
                placement='top'
              >
                {columnConfig[c.columnName].columnContent(policy)}
              </Tooltip>
            ) : (
              columnConfig[c.columnName].columnContent(policy)
            )}
          </Fragment>
        ))}
        <div className='policy-badges'>
          <PolicyStatusChip status={policy.status} />
          {!policy.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
        </div>
        <div className='policies-lost-list-item-icons'>
          <PoliciesSyncIcon tooltip={t('list.tooltips.outdatedPolicy')} enabled={policy.isOutdated} />
        </div>
        <PolicyListItemMenu policy={policy} onPolicyAddCustomer={onPolicyAddCustomer} />
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={policy.id} />}
    </>
  )
}

export default PoliciesLostListItem
