import { useTranslation } from 'react-i18next'
import { isNotaDate } from '../../../services/getMonthName'
import MonthCalendar from '../MonthCalendar/MonthCalendar'
import NoData from '../NoData/NoData'
import './DateRangePicker.scss'

const DateRangePicker = ({ from, to, setFrom, setTo, startDate, endDate }) => {
  const { t } = useTranslation('workstation')
  const setFromHandler = fromDate => {
    if (to && fromDate > to) {
      setTo(fromDate)
    }
    setFrom(fromDate)
  }

  const setToHandler = toDate => {
    if (from && toDate < from) {
      setFrom(toDate)
    }
    setTo(toDate)
  }

  const setFromTo = date => {
    setFrom(date)
    setTo(date)
  }

  if (isNotaDate(startDate) || isNotaDate(endDate)) {
    return (
      <div
        className='month-calendar-container'
        style={{
          width: '100%',
          padding: '24px',
          background: 'var(--background-primary-color)',
        }}
      >
        <NoData text={t('noDatesAvailable')} />
      </div>
    )
  }

  return (
    <div className='date-range-picker-container'>
      {/* <div style={{ position: 'absolute', bottom: '-80px' }}>
        <div>From: {from ? from.toLocaleString() : '-'}</div>
        <div>to: {to ? to.toLocaleString() : '-'}</div>
        <div>start Date: {startDate ? startDate.toLocaleString() : '-'}</div>
        <div>end Date: {endDate ? endDate.toLocaleString() : '-'}</div>
      </div> */}
      <MonthCalendar
        date={from ?? startDate}
        selectedDate={from}
        selectDate={setFromHandler}
        startDate={startDate}
        endDate={endDate}
        from={from}
        to={to}
        fromCalendar
        selectFromTo={setFromTo}
      />
      <MonthCalendar
        date={to ?? endDate}
        selectedDate={to}
        selectDate={setToHandler}
        startDate={startDate}
        endDate={endDate}
        from={from}
        to={to}
        selectFromTo={setFromTo}
      />
    </div>
  )
}

export default DateRangePicker
