import AgencyHouseAnalyticsFiltersPicker from '../../../agencyHouseAnalytics/components/AgencyHouseAnalyticsFiltersPicker/AgencyHouseAnalyticsFiltersPicker'
import PoliciesInput from '../PoliciesInput/PoliciesInput'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  APPLY_POLICIES_FILTERS_DATE,
  APPLY_POLICIES_FILTERS_RANGE,
  APPLY_POLICIES_FILTERS_SEARCH,
  APPLY_POLICIES_FILTERS_SELECT,
  POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY,
} from '../../../../types/actionTypesPolicies'
import PoliciesInputRange from '../PoliciesInputRange/PoliciesInputRange'
import PoliciesDatesFilterDropdown from '../PoliciesDatesFilterDropdown/PoliciesDatesFilterDropdown'
import { useLocation } from 'react-router'
import { agencyPoliciesSubroute } from '../../../../environment/urls'
import { hebrew } from '../../../../i18n'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { Tooltip } from '@mui/material'
import { InfoTwoTone, SystemUpdateAlt } from '@mui/icons-material'
import './PoliciesFilters.scss'
import SelectedFilters from '../../../shared/components/SelectedFilters/SelectedFilters'
import { useState } from 'react'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import { getCheckedIds } from '../../../../utils/objectUtils'
import { UTCDateToShortLocale } from '../../../../utils/date'
import { policiesListType, policiesListTypeMapToApi } from '../../../../types/tablesTypes'
import { exportIrregularPoliciesFile, exportPoliciesFile } from '../../../../http/requests/policiesRequests'

const PoliciesFilters = ({ filterHeader, filterHeaderTooltip, tabType, signalType }) => {
  const [exportPoliciesLoading, setExportPoliciesLoading] = useState(false)
  const { pathname } = useLocation()
  const allRoute = pathname.includes(agencyPoliciesSubroute.all)
  const signalsRoute = pathname.includes(agencyPoliciesSubroute.signals)
  const newRoute = pathname.includes(agencyPoliciesSubroute.new)
  const lostRoute = pathname.includes(agencyPoliciesSubroute.lost)
  const policyTypesRoute = pathname.includes(agencyPoliciesSubroute.types)

  const { t } = useTranslation('policies')
  const dispatch = useDispatch()
  const {
    searchFilters: { customerName, customerIdNumber, policyNumber, reportName },
    selectFilters: { companies, categories, agents, statuses, irregMonths, irregsSort, policyTypes },
    rangeFilters: {
      premiumRange,
      commissionRange,
      accumulationRange,
      commissionRateByPremiumRange,
      commissionRateByAccumulationRange,
      averageCommissionRateRange,
      expectedCommissionRateRange,
    },
    datesFilters: {
      anyMonthInReportsDatesInit,
      firstTimeInReportDatesInit,
      irregularityStartDateInit,
      policyStartDatesInit,
      policyEndDatesInit,
      anyMonthInReportsDates,
      firstTimeInReportDates,
      irregularityStartDate,
      policyStartDates,
      policyEndDates,
    },
    showRangeFilters,
    filtersLoading,
  } = useSelector(({ policies }) => policies)
  const { commissionView } = useSelector(({ shared }) => shared)

  const applyFilter = (value, key, type) => {
    dispatch({
      type,
      payload: {
        key,
        value,
      },
    })
  }

  const handleShowRangeFilters = () => dispatch({ type: POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY })

  const exportPolicies = async () => {
    setExportPoliciesLoading(true)
    try {
      const exportRequest = signalsRoute ? exportIrregularPoliciesFile : exportPoliciesFile
      const requestParams = {
        customerName,
        customerIdNumber,
        policyNumber,
        policyTypes: getCheckedIds(policyTypes, 'selected'),
        policyTypeIds: getCheckedIds(policyTypes, 'selected'),
        companies: getCheckedIds(companies, 'selected'),
        categories: getCheckedIds(categories, 'selected'),
        agents: getCheckedIds(agents, 'selected'),
        statuses: getCheckedIds(statuses, 'selected'),
        policyStartDates: {
          from: policyStartDates.from ? UTCDateToShortLocale(policyStartDates.from) : null,
          to: policyStartDates.to ? UTCDateToShortLocale(policyStartDates.to) : null,
        },
        policyEndDates: {
          from: policyEndDates.from ? UTCDateToShortLocale(policyEndDates.from) : null,
          to: policyEndDates.to ? UTCDateToShortLocale(policyEndDates.to) : null,
        },
        policyFirstTimeInReportsDates: {
          from: firstTimeInReportDates.from ? UTCDateToShortLocale(firstTimeInReportDates.from) : null,
          to: firstTimeInReportDates.to ? UTCDateToShortLocale(firstTimeInReportDates.to) : null,
        },
        anyMonthInReportsDates: {
          from: anyMonthInReportsDates.from ? UTCDateToShortLocale(anyMonthInReportsDates.from) : null,
          to: anyMonthInReportsDates.to ? UTCDateToShortLocale(anyMonthInReportsDates.to) : null,
        },
        irregularityStartDate: {
          from: irregularityStartDate.from ? UTCDateToShortLocale(irregularityStartDate.from) : null,
          to: irregularityStartDate.to ? UTCDateToShortLocale(irregularityStartDate.to) : null,
        },
        premiumRange,
        commissionRange,
        accumulationRange,
        commissionRateByPremiumRange,
        commissionRateByAccumulationRange,
        averageCommissionRateRange,
        expectedCommissionRateRange,
        listType: allRoute
          ? policiesListTypeMapToApi[policiesListType.all]
          : signalsRoute
          ? policiesListTypeMapToApi[policiesListType.signals]
          : newRoute
          ? policiesListTypeMapToApi[policiesListType.new]
          : lostRoute && policiesListTypeMapToApi[policiesListType.lostData],
        tabType,
        months: getCheckedIds(irregMonths, 'selected').map(d => UTCDateToShortLocale(d)),
        sortByMonth: getCheckedIds(irregsSort, 'selected')[0] === 'month',
        signalType,
        commissionView,
      }
      await exportRequest(requestParams)
      setExportPoliciesLoading(false)
    } catch (error) {
      setExportPoliciesLoading(false)
      console.log('Err exporting: ', error)
    }
  }

  return (
    <section className='policies-filters'>
      {filterHeader && (
        <header>
          <p>{filterHeader}</p>
          {filterHeaderTooltip && (
            <Tooltip
              title={filterHeaderTooltip}
              classes={{ popper: 'policies-filters-tooltip-popper' }}
              arrow
              placement={hebrew() ? 'left' : 'right'}
            >
              <InfoTwoTone />
            </Tooltip>
          )}
        </header>
      )}
      <div>
        {!policyTypesRoute && (
          <>
            <PoliciesInput
              value={customerName}
              setValue={val => applyFilter(val, 'customerName', APPLY_POLICIES_FILTERS_SEARCH)}
              placeholder={t(`placeholders.customerName`)}
              showSearchIco
              enableClear
            />
            <PoliciesInput
              value={customerIdNumber}
              setValue={val => applyFilter(val, 'customerIdNumber', APPLY_POLICIES_FILTERS_SEARCH)}
              placeholder={t(`placeholders.customerIdNumber`)}
              showSearchIco
              enableClear
            />
            <PoliciesInput
              value={policyNumber}
              setValue={val => applyFilter(val, 'policyNumber', APPLY_POLICIES_FILTERS_SEARCH)}
              placeholder={t(`placeholders.policyNumber`)}
              showSearchIco
              enableClear
            />
          </>
        )}
        <AgencyHouseAnalyticsFiltersPicker
          applyFilters={ids => applyFilter(ids, 'policyTypes', APPLY_POLICIES_FILTERS_SELECT)}
          filters={policyTypes}
          filtersLoading={filtersLoading}
          filtersTitle={t(`placeholders.policyType`)}
          noFiltersText={'No Policy Types'}
          noFoundText={'Nothing Found'}
          searchPlaceholder={t(`placeholders.search`)}
          selectAllText={t(`placeholders.selectAll`)}
          enableSelectAll
        />
        {policyTypesRoute && (
          <>
            <PoliciesInput
              value={reportName}
              setValue={val => applyFilter(val, 'reportName', APPLY_POLICIES_FILTERS_SEARCH)}
              placeholder={t(`placeholders.reportName`)}
              showSearchIco
              enableClear
            />
            <AgencyHouseAnalyticsFiltersPicker
              applyFilters={ids => applyFilter(ids, 'categories', APPLY_POLICIES_FILTERS_SELECT)}
              filters={categories}
              filtersLoading={filtersLoading}
              filtersTitle={t(`placeholders.categories`)}
              noFiltersText={'No Categories'}
              noFoundText={'Nothing Found'}
              searchPlaceholder={t(`placeholders.search`)}
            />
            <AgencyHouseAnalyticsFiltersPicker
              applyFilters={ids => applyFilter(ids, 'companies', APPLY_POLICIES_FILTERS_SELECT)}
              filters={companies}
              filtersLoading={filtersLoading}
              filtersTitle={t(`placeholders.companies`)}
              noFiltersText={'No Companies'}
              noFoundText={'Nothing Found'}
              searchPlaceholder={t(`placeholders.search`)}
            />
          </>
        )}
        {!policyTypesRoute && !signalsRoute && (
          <PoliciesDatesFilterDropdown
            filtersLoading={filtersLoading}
            policyStartDatesInit={policyStartDatesInit}
            policyEndDatesInit={policyEndDatesInit}
            anyMonthInReportsDatesInit={anyMonthInReportsDatesInit}
            firstTimeInReportDatesInit={firstTimeInReportDatesInit}
            irregularityStartDateInit={irregularityStartDateInit}
            policyStartDates={policyStartDates}
            policyEndDates={policyEndDates}
            anyMonthInReportsDates={anyMonthInReportsDates}
            firstTimeInReportDates={firstTimeInReportDates}
            irregularityStartDate={irregularityStartDate}
            applyFilters={filters => dispatch({ type: APPLY_POLICIES_FILTERS_DATE, payload: filters })}
          />
        )}
      </div>
      {!policyTypesRoute && (
        <div>
          <AgencyHouseAnalyticsFiltersPicker
            applyFilters={ids => applyFilter(ids, 'categories', APPLY_POLICIES_FILTERS_SELECT)}
            filters={categories}
            filtersLoading={filtersLoading}
            filtersTitle={t(`placeholders.categories`)}
            noFiltersText={'No Categories'}
            noFoundText={'Nothing Found'}
            searchPlaceholder={t(`placeholders.search`)}
          />
          <AgencyHouseAnalyticsFiltersPicker
            applyFilters={ids => applyFilter(ids, 'companies', APPLY_POLICIES_FILTERS_SELECT)}
            filters={companies}
            filtersLoading={filtersLoading}
            filtersTitle={t(`placeholders.companies`)}
            noFiltersText={'No Companies'}
            noFoundText={'Nothing Found'}
            searchPlaceholder={t(`placeholders.search`)}
          />
          {signalsRoute && (
            <AgencyHouseAnalyticsFiltersPicker
              applyFilters={ids => applyFilter(ids, 'irregMonths', APPLY_POLICIES_FILTERS_SELECT)}
              filters={irregMonths}
              filtersLoading={filtersLoading}
              filtersTitle={t(`placeholders.irregMonths`)}
              noFiltersText={'No Monoths'}
              noFoundText={'Nothing Found'}
              searchPlaceholder={t(`placeholders.search`)}
            />
          )}
          {!signalsRoute && (
            <>
              <AgencyHouseAnalyticsFiltersPicker
                applyFilters={ids => applyFilter(ids, 'statuses', APPLY_POLICIES_FILTERS_SELECT)}
                filters={statuses}
                filtersLoading={filtersLoading}
                filtersTitle={t(`placeholders.statuses`)}
                noFiltersText={'No Statuses'}
                noFoundText={'Nothing Found'}
                searchPlaceholder={t(`placeholders.search`)}
                multiSelect={true}
              />
              <AgencyHouseAnalyticsFiltersPicker
                applyFilters={ids => applyFilter(ids, 'agents', APPLY_POLICIES_FILTERS_SELECT)}
                filters={agents}
                filtersLoading={filtersLoading}
                filtersTitle={t(`placeholders.agents`)}
                noFiltersText={'No Agents'}
                noFoundText={'Nothing Found'}
                searchPlaceholder={t(`placeholders.search`)}
                selectAllText={t(`placeholders.selectAll`)}
                enableSelectAll
              />
            </>
          )}
        </div>
      )}
      {showRangeFilters && (
        <div className='policies-filters-ranges'>
          {!policyTypesRoute && (
            <>
              <PoliciesInputRange
                range={commissionRange}
                name={t(`placeholders.commissionRange`)}
                setRange={val => applyFilter(val, 'commissionRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
              <PoliciesInputRange
                range={premiumRange}
                name={t(`placeholders.premiumRange`)}
                setRange={val => applyFilter(val, 'premiumRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
              <PoliciesInputRange
                range={accumulationRange}
                name={t(`placeholders.accumulationRange`)}
                setRange={val => applyFilter(val, 'accumulationRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
              <PoliciesInputRange
                range={commissionRateByPremiumRange}
                name={t(`placeholders.commissionRateByPremiumRange`)}
                setRange={val => applyFilter(val, 'commissionRateByPremiumRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
              <PoliciesInputRange
                range={commissionRateByAccumulationRange}
                name={t(`placeholders.commissionRateByAccumulationRange`)}
                setRange={val => applyFilter(val, 'commissionRateByAccumulationRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
            </>
          )}
          {policyTypesRoute && (
            <>
              <PoliciesInputRange
                range={averageCommissionRateRange}
                name={t(`placeholders.avrgCommissionRateRange`)}
                setRange={val => applyFilter(val, 'averageCommissionRateRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
              <PoliciesInputRange
                range={expectedCommissionRateRange}
                name={t(`placeholders.expectedCommissionRateRange`)}
                setRange={val => applyFilter(val, 'expectedCommissionRateRange', APPLY_POLICIES_FILTERS_RANGE)}
              />
            </>
          )}
        </div>
      )}
      <div className='policies-filters-ranges-footer'>
        <ButtonRounded onClick={handleShowRangeFilters}>
          {t(`placeholders.${showRangeFilters ? 'hide' : 'show'}RangeFilters`)}
        </ButtonRounded>
      </div>
      <div className='policies-filters-footer'>
        <div>
          <SelectedFilters />
        </div>
        <div>
          {!policyTypesRoute && (
            <>
              <ButtonRounded classes='policies-export-btn' onClick={exportPolicies} disabled={exportPoliciesLoading}>
                <span>{t('exportPoliciesBtn')}</span>
                <SystemUpdateAlt />
              </ButtonRounded>
              {exportPoliciesLoading && <LoadingOverlay fullscreen text={t('exportPoliciesBtn') + '...'} />}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default PoliciesFilters
