import { useState, useRef, useEffect } from 'react'
import { hebrew, rtlDir } from '../../../../i18n'
import { ArrowDropDown, ArrowDropUp, Cancel, CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material'
import InputSearch from '../../../../components/Common/InputSearch/InputSearch'
import NoData from '../../../../components/Common/NoData/NoData'
import { cn } from '../../../../utils/stylesUtils'
import { createPortal } from 'react-dom'
import { arraysHaveSameItems } from '../../../../utils/arraysUtils'
import './AgencyHouseAnalyticsFiltersPicker.scss'

const AgencyHouseAnalyticsFiltersPicker = ({
  filters,
  filtersLoading,
  applyFilters,
  filtersTitle,
  noFoundText,
  noFiltersText,
  searchPlaceholder,
  multiSelect = true,
  showSearch = true,
  enableClear = true,
  showSelectedValues = true,
  selectAllText = 'Select All',
  enableSelectAll = false,
}) => {
  const [showPicker, setShowPicker] = useState(false)
  const [filtersDirty, setFiltersDirty] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [searchName, setSearchName] = useState('')
  const [selectedValueTitle, setSelectedValueTitle] = useState(filtersTitle)
  const [selectedStack, setSelectedStack] = useState([])
  const btnRef = useRef()
  const overlayRef = useRef()
  const selectedRef = useRef()

  const hasFiltersApplied = filters.some(a => a.selected)

  const getButtonPostition = () => {
    const { bottom, left, right, width } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom, width }
  }

  const filteredFilters = filters.filter(a =>
    a[hebrew() ? 'name' : 'nameEn']?.toLowerCase().includes(searchName.toLowerCase())
  )

  const allSelected = arraysHaveSameItems(
    selectedIds,
    filteredFilters.map(f => f.id)
  )

  const selectAllHandler = () => {
    let idsToSelect = []
    if (!allSelected) {
      idsToSelect = filteredFilters.map(c => c.id)
    }
    setFiltersDirty(true)
    setSelectedIds(idsToSelect)
    setSelectedStack(idsToSelect)
  }

  const applyFiltersHandler = id => {
    if (id && !selectedIds.includes(id)) {
      applyFilters([id])
    } else {
      filtersDirty && applyFilters(selectedIds)
    }
    setFiltersDirty(false)
    setShowPicker(false)
  }

  const updateSelectedValue = id => {
    if (selectedStack.includes(id)) setSelectedStack(selectedStack.filter(i => i !== id))
    else setSelectedStack([...selectedStack, id])
  }

  useEffect(() => {
    for (let index = 0; index < selectedStack.length; index++) {
      const selectedId = selectedStack[index]
      if (
        !filters
          .filter(f => f.selected === true)
          .map(f => f.id)
          .includes(selectedId) &&
        !showPicker
      ) {
        setSelectedStack(selectedStack.filter(id => id !== selectedId))
      }
    }
    if (selectedStack.length > 0) {
      var selectedTitle = filters.find(f => f.id === selectedStack[0])[hebrew() ? 'name' : 'nameEn']
      if (selectedStack.length > 1) {
        selectedTitle = hebrew() ? '...' + selectedTitle : selectedTitle + '...'
      }
      setSelectedValueTitle(selectedTitle)
    } else {
      setSelectedValueTitle(filtersTitle)
    }
  }, [selectedStack, filtersTitle, filters, showPicker])

  useEffect(() => {
    if (showPicker) {
      setSelectedIds(filters.filter(a => a.selected).map(a => a.id))
      setSearchName('')
    }
    if (!showPicker) {
      setSelectedIds([])
    }
  }, [filters, showPicker])

  useEffect(() => {
    if (showPicker) {
      if (selectedRef?.current) {
        selectedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      } else {
        selectedRef?.current?.scrollTop()
      }
    }
  }, [showPicker])

  return (
    <div className='agency-house-analytics-filters-picker-container'>
      <div className='agency-house-analytics-filters-picker-btn-container' ref={btnRef}>
        <div
          className={cn('agency-house-analytics-filters-picker-btn', {
            'has-applied': hasFiltersApplied,
            open: showPicker,
          })}
          onClick={() => (showPicker ? applyFiltersHandler() : setShowPicker(!showPicker))}
          disabled={filtersLoading}
        >
          <span>{showSelectedValues ? selectedValueTitle : filtersTitle}</span>
          {hasFiltersApplied && enableClear ? (
            <button
              onClick={e => {
                e.stopPropagation()
                applyFilters([])
                setSelectedStack([])
              }}
            >
              <Cancel />
            </button>
          ) : showPicker ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
      </div>
      {showPicker &&
        createPortal(
          <div
            className='agency-house-analytics-filters-picker-overlay'
            ref={overlayRef}
            onClick={e => {
              overlayRef.current === e.target && applyFiltersHandler()
            }}
          >
            <div
              className='agency-house-analytics-filters-picker-main-container'
              style={{
                width: getButtonPostition().width + 'px',
                top: getButtonPostition().y + 8 + 'px',
                ...(rtlDir()
                  ? { left: getButtonPostition().left + 'px' }
                  : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
              }}
            >
              {filters.length === 0 ? (
                <NoData text={noFiltersText} />
              ) : (
                <>
                  {showSearch && (
                    <div className='agency-house-analytics-filters-picker-search-container'>
                      <InputSearch placeholder={searchPlaceholder} searchHandler={setSearchName} />
                    </div>
                  )}
                  {filteredFilters.length > 1 && enableSelectAll && (
                    <div
                      className={cn('agency-house-analytics-filters-picker-agent-container select-all', {
                        selected: allSelected,
                      })}
                      onClick={selectAllHandler}
                    >
                      {allSelected ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                      <span>{selectAllText}</span>
                    </div>
                  )}
                  <div className='agency-house-analytics-filters-picker-agents-container'>
                    {filteredFilters.length === 0 && <NoData text={noFoundText} />}
                    {filteredFilters.map(({ id, name, nameEn }) => (
                      <div
                        key={id}
                        className={cn('agency-house-analytics-filters-picker-agent-container', {
                          selected: selectedIds.includes(id),
                        })}
                        onClick={() => {
                          setFiltersDirty(true)
                          setSelectedIds(prev =>
                            prev.includes(id) ? prev.filter(itm => itm !== id) : [...(multiSelect ? [...prev] : []), id]
                          )
                          updateSelectedValue(id)
                          !multiSelect && applyFiltersHandler(id)
                        }}
                        {...(selectedIds.includes(id) && { ref: selectedRef })}
                      >
                        {selectedIds.includes(id) ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                        <span>{hebrew() ? name : nameEn}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>,
          document.body
        )}
    </div>
  )
}

export default AgencyHouseAnalyticsFiltersPicker
