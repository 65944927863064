import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import './PolicyListItemCard.scss'

const PolicyListItemCard = ({ classes, children, selectable, selected, onSelect, onClick, styles = {} }) => {
  const selectButtonClickHandler = e => {
    e.stopPropagation()
    onSelect()
  }
  return (
    <div
      className={cn('policy-list-item-card', classes, { clickable: onClick, selected })}
      {...(onClick && { onClick })}
      style={styles}
    >
      {selectable && (
        <div className='policy-list-item-select'>
          <button onClick={selectButtonClickHandler}>{selected ? <CheckBox /> : <CheckBoxOutlineBlank />}</button>
        </div>
      )}
      {children}
    </div>
  )
}

export default PolicyListItemCard
