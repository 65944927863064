import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import { cn } from '../../../../utils/stylesUtils'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { formatAsCurrency } from '../../../../utils/formatAs'
import PoliciesNewListItem from './PoliciesNewListItem'
import { allPoliciesListType } from '../../../../types/tablesTypes'
import { useDispatch, useSelector } from 'react-redux'
import { POLICIES_SET_SORT_BY } from '../../../../types/actionTypesPolicies'
import SortableColumn from '../../../../components/SortableColumn/SortableColumn'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow, getColumnName } from '../../../../services/columnsConfig/columnsConfigHelpers'
import ColumnsPicker from '../../../../components/ColumnsPicker/ColumnsPicker'
import './PoliciesNewList.scss'

export const PoliciesNewList = ({
  list,
  listType,
  loading,
  collapsed,
  onCollapse,
  title,
  showDetails,
  selectPolicy,
  selectedPolicies,
}) => {
  const { t } = useTranslation('policies')
  const elementaryListType = listType === allPoliciesListType.elementary
  const { sort } = useSelector(({ policies }) => policies)
  const dispatch = useDispatch()
  const setSortByHandler = listKey => sortBy => dispatch({ type: POLICIES_SET_SORT_BY, payload: { sortBy, listKey } })

  let columnConfigPage
  let sortListKey
  switch (listType) {
    case allPoliciesListType.elementary:
      columnConfigPage = columnsConfigPage.policiesNewElementary
      sortListKey = 'Elementary'
      break
    case allPoliciesListType.lifeFinance:
    default:
      columnConfigPage = columnsConfigPage.policiesNewLife
      sortListKey = 'Life'
      break
  }

  const availableColumns = getAvailableColumnsToShow(columnConfigPage)
  const availableColumnsNames = availableColumns.map(c => c.columnName)

  const columnsGridStyles = {
    display: 'grid',
    gridTemplateColumns: `48px ${
      availableColumns.length === 0 ? '1fr' : availableColumns.map(c => c.cssGridColumnWidth).join(' ')
    } 1fr 1fr ${availableColumns.length > 0 ? '32px' : ''}`,
  }

  return (
    <div className='policies-new-list-wrapper'>
      {title && (
        <LinedTitle
          title={title}
          collapsible
          onCollapse={onCollapse}
          collapsed={collapsed}
          count={t('placeholders.policiesCount', { count: list?.totalCount ?? '--' })}
        />
      )}
      {(!title || (title && !collapsed)) && (
        <>
          {loading ? <Loader /> : list?.items?.length === 0 && <NoData text={t('placeholders.noSearchResults')} />}
          {!loading && list?.items?.length > 0 && (
            <>
              <div
                className={cn(
                  `policies-new-list-header policies-new-list-grid ${
                    elementaryListType && 'policies-new-list-grid-elementary'
                  }`
                )}
                style={columnsGridStyles}
              >
                <div></div>
                {availableColumns.map(c => (
                  <SortableColumn
                    key={c.id}
                    title={getColumnName(c, t, 'list.heading')}
                    columnSortBy={c.columnName}
                    columnSortOrder={sort[`order${sortListKey}`]}
                    currentSortBy={sort[`by${sortListKey}`]}
                    setSort={setSortByHandler(sortListKey)}
                    textSort={columnConfig[c.columnName].textSort}
                  />
                ))}
                <div></div>
                <div></div>
                <div>
                  <ColumnsPicker listKey={columnConfigPage} t={t} translationKey={'list.heading'} />
                </div>
              </div>
              <div className='policies-new-list'>
                {availableColumns.length === 0 && <NoData text={t('noColumnsSelected')} />}
                {availableColumns.length > 0 &&
                  list.items.map(p => (
                    <PoliciesNewListItem
                      key={p.id}
                      policy={p}
                      selectPolicy={selectPolicy}
                      selected={selectedPolicies.includes(p.id)}
                      listType={listType}
                      classes={`policies-new-list-grid ${elementaryListType && 'policies-new-list-grid-elementary'}`}
                      styles={columnsGridStyles}
                    />
                  ))}
              </div>
              <PolicyListItemCard
                classes={`policies-new-list-grid policies-new-list-total ${
                  elementaryListType && 'policies-new-list-grid-elementary'
                }`}
                styles={columnsGridStyles}
              >
                <div>{t('list.heading.listTotal')}</div>
                <div></div>
                {availableColumnsNames.includes(columnType.policyNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.customerName) && <div></div>}
                {availableColumnsNames.includes(columnType.customerIdNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.employerName) && <div></div>}
                {availableColumnsNames.includes(columnType.employerNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.company) && <div></div>}
                {availableColumnsNames.includes(columnType.category) && <div></div>}
                {availableColumnsNames.includes(columnType.startDate) && <div></div>}
                {availableColumnsNames.includes(columnType.endDate) && <div></div>}
                {availableColumnsNames.includes(columnType.policyAge) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionAgency) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionAgency'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commission) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommission'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionBothTotal) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionAgencyBothTotal'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premium) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalPremium, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premiumBrutto) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalPremiumBrutto, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.fees) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalFees, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.accumulation) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalAccumulation, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionMonthlyAgency) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionMonthlyAgency'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionMonthly) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionMonthly'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionMonthlyBothTotal) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionMonthlyBothTotal'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premiumMonthly) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.premiumMonthly, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premiumBruttoMonthly) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.premiumBruttoMonthly, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionRatePremiumAgency) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRatePremium) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRatePremiumBothTotal) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulationAgency) && <div></div>}
                {availableColumnsNames.includes(columnType.premiumMontcommissionRateAccumulationhly) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulationBothTotal) && <div></div>}
                <div></div>
              </PolicyListItemCard>
            </>
          )}
          {title && !loading && (
            <div className='policies-new-list-footer'>
              <ButtonRounded onClick={showDetails}>{t('placeholders.showMoreBtn')}</ButtonRounded>
            </div>
          )}
        </>
      )}
    </div>
  )
}
