import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import { useTranslation } from 'react-i18next'
import PolicyTypeListItem from './PolicyTypeListItem'
import './PolicyTypesList.scss'
import { columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow } from '../../../../services/columnsConfig/columnsConfigHelpers'
import ColumnsPicker from '../../../../components/ColumnsPicker/ColumnsPicker'

const PolicyTypesList = ({ list, loading, updateListItem }) => {
  const { t } = useTranslation('policies')
  if (loading || list.length === 0) {
    return (
      <div className='policy-types-list-wrapper'>
        {loading ? <Loader /> : <NoData text={t('placeholders.noSearchResults')} />}
      </div>
    )
  }

  const columnConfigPage = columnsConfigPage.coverage
  const availableColumnsNames = getAvailableColumnsToShow(columnConfigPage).map(c => c.columnName)

  const columnsGridStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${availableColumnsNames.length === 0 ? 1 : availableColumnsNames.length}, 1fr) ${
      availableColumnsNames.length > 0 ? '32px' : ''
    }`,
  }

  return (
    <div className='policy-types-list-wrapper'>
      <div className='policy-types-list-header policy-types-list-grid' style={columnsGridStyles}>
        {availableColumnsNames.includes(columnType.policyTypeName) && <div>{t('list.heading.policyTypeName')}</div>}
        {availableColumnsNames.includes(columnType.company) && <div>{t('list.heading.company')}</div>}
        {availableColumnsNames.includes(columnType.category) && <div>{t('list.heading.category')}</div>}
        {availableColumnsNames.includes(columnType.reportName) && <div>{t('list.heading.reportName')}</div>}
        {availableColumnsNames.includes(columnType.averageCommissionRate) && (
          <div>{t('list.heading.averageCommissionRate')}</div>
        )}
        {availableColumnsNames.includes(columnType.expectedCommissionRate) && (
          <div>{t('list.heading.expectedCommissionRate')}</div>
        )}
        <div>
          <ColumnsPicker listKey={columnConfigPage} t={t} translationKey={'list.heading'} />
        </div>
      </div>
      <div className='policy-types-list'>
        {availableColumnsNames.length === 0 && <NoData text={t('noColumnsSelected')} />}
        {availableColumnsNames.length > 0 &&
          list.map(pt => (
            <PolicyTypeListItem
              policyType={pt}
              key={pt.id}
              updatePolicyType={updateListItem}
              styles={columnsGridStyles}
            />
          ))}
      </div>
    </div>
  )
}

export default PolicyTypesList
