import { reportErrors } from './ErrorsLog'

export const reportRecordStatusType = {
  none: 10,
  loginError: 15,
  resetPasswordRequired: 16,
  vaultError: 17,
  downloadCanceled: 18,
  unexpectedPage: 19,
  downloadFailed: 20,
  notAccessible: 25,
  noPermission: 27,
  siteError: 29,
  unknownScanError: 30,
  downloaded: 40,
  saved: 50,
  saveFailed: 55,
  fileCorrupted: 57,
  noData: 60,
  reportNotFound: 65,
  inCorrectFileMonth: 70,
  userFrosen: 75,
}

export const reportRecordActionType = {
  Retry: 'Retry',
  OK: 'OK',
  UpdateCredentials: 'UpdateCredentials',
  UpdateVaultCredentials: 'UpdateVaultCredentials',
}

export const reportRecordLinkType = {
  site: 'site',
  support: 'support',
}

export const reportRecordStatus = reportErrors.reduce((acc, curErr) => {
  return {
    ...acc,
    [curErr.status]: curErr,
  }
}, {})
