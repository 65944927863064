import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PoliciesSignalsListItem from './PoliciesSignalsListItem'
import { cn } from '../../../../utils/stylesUtils'
import { formatAsCurrency } from '../../../../utils/formatAs'
import SortableColumn from '../../../../components/SortableColumn/SortableColumn'
import { POLICIES_SET_SORT_BY } from '../../../../types/actionTypesPolicies'
import { useDispatch, useSelector } from 'react-redux'
import { columnConfig, columnsConfigPage, columnType } from '../../../../services/columnsConfig/columnsConfigTypes'
import { getAvailableColumnsToShow, getColumnName } from '../../../../services/columnsConfig/columnsConfigHelpers'
import ColumnsPicker from '../../../../components/ColumnsPicker/ColumnsPicker'
import './PoliciesSignalsList.scss'

export const PoliciesSignalsList = ({
  list,
  loading,
  collapsed,
  onCollapse,
  title,
  showDetails,
  selectIrreg,
  selectedIrregs,
}) => {
  const { t } = useTranslation('policies')
  const { sort } = useSelector(({ policies }) => policies)
  const dispatch = useDispatch()
  const setSortByHandler = sortBy => dispatch({ type: POLICIES_SET_SORT_BY, payload: { sortBy, listKey: 'Signals' } })
  const columnConfigPage = columnsConfigPage.irregularPolicy

  const availableColumns = getAvailableColumnsToShow(columnConfigPage)
  const availableColumnsNames = availableColumns.map(c => c.columnName)

  const columnsGridStyles = {
    display: 'grid',
    gridTemplateColumns: `48px ${
      availableColumns.length === 0 ? '1fr' : availableColumns.map(c => c.cssGridColumnWidth).join(' ')
    } 1fr ${availableColumns.length > 0 ? '.6fr' : ''}`,
  }

  return (
    <div className='policies-signals-list-wrapper'>
      {title && (
        <LinedTitle
          title={title}
          collapsible
          onCollapse={onCollapse}
          collapsed={collapsed}
          count={t('placeholders.signalsCount', { count: list?.totalCount ?? '--' })}
        />
      )}
      {(!title || (title && !collapsed)) && (
        <>
          {loading ? <Loader /> : list?.items?.length === 0 && <NoData text={t('placeholders.noSearchResults')} />}
          {!loading && list?.items?.length > 0 && (
            <>
              <div className={cn('policies-signals-list-header policies-signals-list-grid')} style={columnsGridStyles}>
                <div></div>
                {availableColumns.map(c => (
                  <SortableColumn
                    key={c.id}
                    title={getColumnName(c, t, 'list.heading')}
                    columnSortBy={c.columnName}
                    columnSortOrder={sort.orderSignals}
                    currentSortBy={sort.bySignals}
                    setSort={setSortByHandler}
                    textSort={columnConfig[c.columnName].textSort}
                  />
                ))}
                <div></div>
                <div>
                  <ColumnsPicker listKey={columnConfigPage} t={t} translationKey={'list.heading'} />
                </div>
              </div>
              <div className='policies-signals-list'>
                {availableColumns.length === 0 && <NoData text={t('noColumnsSelected')} />}
                {availableColumns.length > 0 &&
                  list.items.map(s => (
                    <PoliciesSignalsListItem
                      key={s.irregularityId}
                      signal={s}
                      classes={'policies-signals-list-grid '}
                      selectIrreg={selectIrreg}
                      selected={selectedIrregs.includes(s.irregularityId)}
                      styles={columnsGridStyles}
                    />
                  ))}
              </div>
              <PolicyListItemCard
                classes={'policies-signals-list-grid policies-signals-list-total '}
                styles={columnsGridStyles}
              >
                <div>{t('list.heading.listTotal')}</div>
                <div></div>
                {availableColumnsNames.includes(columnType.month) && <div></div>}
                {availableColumnsNames.includes(columnType.monthlyIrregularitiesCount) && <div></div>}
                {availableColumnsNames.includes(columnType.policyNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.customerName) && <div></div>}
                {availableColumnsNames.includes(columnType.customerIdNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.employerName) && <div></div>}
                {availableColumnsNames.includes(columnType.employerNumber) && <div></div>}
                {availableColumnsNames.includes(columnType.company) && <div></div>}
                {availableColumnsNames.includes(columnType.category) && <div></div>}
                {availableColumnsNames.includes(columnType.policyAge) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionAgency) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionAgency'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commission) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommission'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionBothTotal) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['commissionBothTotal'], { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premium) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalPremium, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.premiumBrutto) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalPremiumBrutto, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.fees) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalFees, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.accumulation) && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list.totalAccumulation, { dashesForZero: false })}
                  </div>
                )}
                {availableColumnsNames.includes(columnType.commissionRatePremiumAgency) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRatePremium) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRatePremiumBothTotal) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulationAgency) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulation) && <div></div>}
                {availableColumnsNames.includes(columnType.commissionRateAccumulationBothTotal) && <div></div>}
                <div></div>
                <div></div>
              </PolicyListItemCard>
            </>
          )}
          {title && !loading && (
            <div className='policies-signals-list-footer'>
              <ButtonRounded onClick={showDetails}>{t('placeholders.showMoreBtn')}</ButtonRounded>
            </div>
          )}
        </>
      )}
    </div>
  )
}
