import { useTranslation } from 'react-i18next'
import PoliciesFilters from '../PoliciesFilters/PoliciesFilters'
import { allPoliciesList, allPoliciesListType, tabPaymentLabelsNames } from '../../../../types/tablesTypes'
import ButtonTab from '../../../../components/Common/ButtonTab/ButtonTab'
import { formatAsNumber } from '../../../../utils/formatAs'
import { useCallback, useEffect, useState } from 'react'
import { PoliciesAllList } from './PoliciesAllList'
import { useSelector } from 'react-redux'
import { getCheckedIds } from '../../../../utils/objectUtils'
import { getAllPoliciesRequest, getSpecialPaymentsRequest } from '../../../../http/requests/policiesRequests'
import { UTCDateToShortLocale } from '../../../../utils/date'
import PoliciesListPageSize from '../PoliciesListPageSize/PoliciesListPageSize'
import './PoliciesAllListContainer.scss'
import PoliciesAppBar from '../PoliciesAppBar/PoliciesAppBar'
import { sortOrder } from '../../../../types/sortByTypes'

const PoliciesAllListContainer = () => {
  const { t } = useTranslation('policies')
  const [listType, setListType] = useState(allPoliciesListType.all)
  const [lifeList, setLifeList] = useState([])
  const [elementaryList, setElementaryList] = useState([])
  const [specialList, setSpecialList] = useState([])
  const [listLoading, setListLoading] = useState(true)
  const [collapsedLists, setCollapsedLists] = useState({})
  const [pageSize, setPageSize] = useState(50)
  const [selectedPolicies, setSelectedPolicies] = useState([])
  const [page, setPage] = useState(1)

  const {
    searchFilters: { customerName, customerIdNumber, policyNumber },
    selectFilters: { companies, categories, agents, statuses, policyTypes },
    rangeFilters,
    datesFilters: {
      anyMonthInReportsDates,
      firstTimeInReportDates,
      irregularityStartDate,
      policyStartDates,
      policyEndDates,
    },
    sort,
  } = useSelector(({ policies }) => policies)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const handleCollpaseList = listType => setCollapsedLists(prev => ({ ...prev, [listType]: !prev[listType] }))

  const selectPolicyHanlder = id => {
    setSelectedPolicies(prev => (prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]))
  }

  const getPolicesTotalCount = listType => {
    const lifeCount = lifeList?.totalCount ?? 0
    const elementaryCount = elementaryList?.totalCount ?? 0
    const specialCount = specialList?.totalCount ?? 0
    switch (listType) {
      case allPoliciesListType.all:
        return lifeCount + elementaryCount + specialCount
      case allPoliciesListType.lifeFinance:
        return lifeCount
      case allPoliciesListType.elementary:
        return elementaryCount
      case allPoliciesListType.specialPayments:
        return specialCount
      default:
        return 0
    }
  }

  const setPageSizeHandler = pageSize => {
    setPage(1)
    setPageSize(pageSize)
  }

  const fetchList = useCallback(
    async ({ silentLoading = false } = {}) => {
      try {
        setListLoading(!silentLoading)
        const requestParams = {
          customerName,
          customerIdNumber,
          policyNumber,
          policyTypes: getCheckedIds(policyTypes, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          categories: getCheckedIds(categories, 'selected'),
          agents: getCheckedIds(agents, 'selected'),
          statuses: getCheckedIds(statuses, 'selected'),
          policyStartDates: {
            from: policyStartDates.from ? UTCDateToShortLocale(policyStartDates.from) : null,
            to: policyStartDates.to ? UTCDateToShortLocale(policyStartDates.to) : null,
          },
          policyEndDates: {
            from: policyEndDates.from ? UTCDateToShortLocale(policyEndDates.from) : null,
            to: policyEndDates.to ? UTCDateToShortLocale(policyEndDates.to) : null,
          },
          policyFirstTimeInReportsDates: {
            from: firstTimeInReportDates.from ? UTCDateToShortLocale(firstTimeInReportDates.from) : null,
            to: firstTimeInReportDates.to ? UTCDateToShortLocale(firstTimeInReportDates.to) : null,
          },
          anyMonthInReportsDates: {
            from: anyMonthInReportsDates.from ? UTCDateToShortLocale(anyMonthInReportsDates.from) : null,
            to: anyMonthInReportsDates.to ? UTCDateToShortLocale(anyMonthInReportsDates.to) : null,
          },
          irregularityStartDate: {
            from: irregularityStartDate.from ? UTCDateToShortLocale(irregularityStartDate.from) : null,
            to: irregularityStartDate.to ? UTCDateToShortLocale(irregularityStartDate.to) : null,
          },
          ...rangeFilters,
          pageSize: listType === allPoliciesListType.all ? 10 : pageSize,
          pageNumber: page,
        }
        const requests = []
        if (listType === allPoliciesListType.all || listType === allPoliciesListType.lifeFinance) {
          requests.push(
            getAllPoliciesRequest({
              ...requestParams,
              tabType: +allPoliciesListType.lifeFinance,
              sortBy: sort.byLife,
              descending: sort.orderLife === sortOrder.desc,
            })
          )
        }
        if (listType === allPoliciesListType.all || listType === allPoliciesListType.elementary) {
          requests.push(
            getAllPoliciesRequest({
              ...requestParams,
              tabType: +allPoliciesListType.elementary,
              sortBy: sort.byElementary,
              descending: sort.orderElementary === sortOrder.desc,
            })
          )
        }
        if (listType === allPoliciesListType.all || listType === allPoliciesListType.specialPayments) {
          requests.push(
            getSpecialPaymentsRequest({
              ...requestParams,
              sortBy: sort.bySpecial,
              descending: sort.orderSpecial === sortOrder.desc,
            })
          )
        }
        const [data1, data2, data3] = await Promise.all(requests)
        if (listType === allPoliciesListType.all) {
          setLifeList(data1.data.data.lifePolicies)
          setElementaryList(data2.data.data.elementaryPolicies)
          setSpecialList(data3.data.data)
          setCollapsedLists({
            [allPoliciesListType.lifeFinance]: data1.data.data.lifePolicies.items.length === 0,
            [allPoliciesListType.elementary]: data2.data.data.elementaryPolicies.items.length === 0,
            [allPoliciesListType.specialPayments]: true,
          })
        } else {
          listType === allPoliciesListType.lifeFinance
            ? setLifeList(data1.data.data.lifePolicies)
            : listType === allPoliciesListType.elementary
            ? setElementaryList(data1.data.data.elementaryPolicies)
            : setSpecialList(data1.data.data)
        }
        setListLoading(false)
      } catch (error) {
        console.log('Error catch')
        console.log(error)
        setListLoading(false)
      }
    },
    [
      listType,
      customerName,
      customerIdNumber,
      policyNumber,
      policyTypes,
      companies,
      categories,
      agents,
      statuses,
      rangeFilters,
      policyStartDates,
      policyEndDates,
      firstTimeInReportDates,
      anyMonthInReportsDates,
      irregularityStartDate,
      pageSize,
      page,
      sort,
    ]
  )

  useEffect(() => {
    fetchList()
  }, [fetchList, selectedGroupsIds])

  useEffect(() => {
    setPage(1)
  }, [
    listType,
    customerName,
    customerIdNumber,
    policyNumber,
    policyTypes,
    companies,
    categories,
    agents,
    statuses,
    rangeFilters,
    policyStartDates,
    policyEndDates,
    firstTimeInReportDates,
    anyMonthInReportsDates,
    irregularityStartDate,
    selectedGroupsIds,
    sort,
  ])

  useEffect(() => {
    setSelectedPolicies([])
  }, [listType])

  return (
    <div className='policies-all-list-container'>
      <header className='policies-list-header'>
        <section className='policies-list-tabs'>
          {Object.values(allPoliciesList).map(l => (
            <ButtonTab
              key={l.key}
              tabName={t(`screenTabs.allPolicies.${l.key}`)}
              badge={formatAsNumber(getPolicesTotalCount(l.type)) + ' ' + t(`${tabPaymentLabelsNames[l.type]}`)}
              selected={listType === l.type}
              onClick={() => setListType(l.type)}
            />
          ))}
        </section>
      </header>
      <div className='policies-filters-wrapper'>
        <PoliciesFilters
          filterHeader={t(`screenTabs.allPolicies.${allPoliciesList[listType].key}`)}
          tabType={+listType}
        />
      </div>
      <div className='policies-all-lists-wrapper'>
        {(listType === allPoliciesListType.all || listType === allPoliciesListType.lifeFinance) && (
          <PoliciesAllList
            {...(listType === allPoliciesListType.all && {
              title: t(`screenTabs.allPolicies.${allPoliciesList[allPoliciesListType.lifeFinance].key}`),
            })}
            onCollapse={() => handleCollpaseList(allPoliciesListType.lifeFinance)}
            collapsed={collapsedLists[allPoliciesListType.lifeFinance]}
            showDetails={() => setListType(allPoliciesListType.lifeFinance)}
            listType={allPoliciesListType.lifeFinance}
            list={lifeList}
            loading={listLoading}
            selectPolicy={selectPolicyHanlder}
            selectedPolicies={selectedPolicies}
          />
        )}
        {(listType === allPoliciesListType.all || listType === allPoliciesListType.elementary) && (
          <PoliciesAllList
            {...(listType === allPoliciesListType.all && {
              title: t(`screenTabs.allPolicies.${allPoliciesList[allPoliciesListType.elementary].key}`),
            })}
            onCollapse={() => handleCollpaseList(allPoliciesListType.elementary)}
            collapsed={collapsedLists[allPoliciesListType.elementary]}
            showDetails={() => setListType(allPoliciesListType.elementary)}
            listType={allPoliciesListType.elementary}
            list={elementaryList}
            loading={listLoading}
            selectPolicy={selectPolicyHanlder}
            selectedPolicies={selectedPolicies}
          />
        )}
        {(listType === allPoliciesListType.all || listType === allPoliciesListType.specialPayments) && (
          <PoliciesAllList
            {...(listType === allPoliciesListType.all && {
              title: t(`screenTabs.allPolicies.${allPoliciesList[allPoliciesListType.specialPayments].key}`),
            })}
            onCollapse={() => handleCollpaseList(allPoliciesListType.specialPayments)}
            collapsed={collapsedLists[allPoliciesListType.specialPayments]}
            showDetails={() => setListType(allPoliciesListType.specialPayments)}
            listType={allPoliciesListType.specialPayments}
            list={specialList}
            loading={listLoading}
            selectPolicy={selectPolicyHanlder}
            selectedPolicies={selectedPolicies}
          />
        )}
        {selectedPolicies.length > 0 && (
          <div className='policies-lists-appbar-wrapper'>
            <PoliciesAppBar
              closeBar={() => setSelectedPolicies([])}
              policies={[...lifeList.items, ...elementaryList.items, ...specialList.items].filter(p =>
                selectedPolicies.includes(p.id)
              )}
              onUpdateCategory={() => fetchList({ silentLoading: true })}
            />
          </div>
        )}
      </div>
      {listType !== allPoliciesListType.all && (
        <PoliciesListPageSize
          pageSize={pageSize}
          setPageSize={setPageSizeHandler}
          itemsCount={getPolicesTotalCount(listType)}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  )
}

export default PoliciesAllListContainer
