import { createPortal } from 'react-dom'
import { rtlDir } from '../../../i18n'
import { useLayoutEffect, useState, useRef } from 'react'
import './RelativeFixedPosition.scss'
import { useTranslation } from 'react-i18next'

const RelativeFixedPosition = ({
  children,
  usePortal = true,
  useOverlay = true,
  anchorRef,
  topOffset = 8,
  onOverlayClick,
}) => {
  const [styles, setStyles] = useState({})
  const containerRef = useRef(null)
  const {
    i18n: { language },
  } = useTranslation()

  useLayoutEffect(() => {
    const getParentPosition = () => {
      if (!anchorRef?.current) return { left: 0, right: 0, y: 0, bottom: 0 }
      const { bottom, left, right, top } = anchorRef.current.getBoundingClientRect()
      return { left, right, y: bottom, top }
    }

    const updateStyles = () => {
      if (!anchorRef?.current || !containerRef.current) return
      const position = getParentPosition()
      const viewportHeight = window.innerHeight

      const containerHeight = containerRef.current.offsetHeight || 0
      const isOutOfView = position.y + topOffset + containerHeight > viewportHeight

      setStyles({
        top: isOutOfView ? position.top - containerHeight - topOffset + 'px' : position.y + topOffset + 'px',
        ...(rtlDir()
          ? { left: position.left + 'px' }
          : { left: position.right + 'px', transform: 'translateX(-100%)' }),
      })
    }

    updateStyles()

    const handleResizeOrScroll = () => {
      requestAnimationFrame(updateStyles)
    }

    window.addEventListener('resize', handleResizeOrScroll)
    window.addEventListener('scroll', handleResizeOrScroll)

    return () => {
      window.removeEventListener('resize', handleResizeOrScroll)
      window.removeEventListener('scroll', handleResizeOrScroll)
    }
  }, [anchorRef, topOffset, language])

  const baseContent = (
    <div
      ref={containerRef}
      className='relative-fixed-container'
      style={styles}
      {...(onOverlayClick && {
        onClick: e => {
          e.stopPropagation()
        },
      })}
    >
      {children}
    </div>
  )

  const content =
    useOverlay || onOverlayClick ? (
      <div
        className='relative-fixed-overlay'
        {...(onOverlayClick && {
          onClick: () => {
            onOverlayClick()
          },
        })}
      >
        {baseContent}
      </div>
    ) : (
      baseContent
    )

  return usePortal ? createPortal(content, document.body) : content
}

export default RelativeFixedPosition
